import {
	Card,
	FormLayout,
	Loading,
	Page,
	Spinner,
	TextField,
	Thumbnail,
	Toast,
} from '@shopify/polaris';
import { AxiosInstance } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import DefaultLayout from '../../layout/default';
import { ErrorBanner } from '../../utils/components/ErrorBanner';
import {
	LOCAL_STORAGE_KEY,
	MAX_IMAGE_SIZE,
	SERVER_BASE_URL,
	SUPPORTED_IMAGE_MIMETYPES,
} from '../../utils/constants';
import { isEmail } from '../../utils/validations';
import useAxios from '../../utils/hooks/useAxios.hook';
import {
	ClubFullDetails,
	ClubURLParams,
	GetClubResponse,
	UploadFileResponse,
} from '../../utils/types';
import noLogoTemplate from '../../assets/images/noLogoTemplate.jpeg';
import { v4 } from 'uuid';

export function EditClub() {
	const { clubId } = useParams<ClubURLParams>();
	const [club, setClub] = useState<ClubFullDetails>(null);
	const [clubS3Id, setClubS3Id] = useState<string>();

	const token = localStorage.getItem(LOCAL_STORAGE_KEY);
	const http: AxiosInstance = useAxios(token);

	const [toastActive, setErrorToastActive] = useState(false);
	const [errorToastContent, setErrorToastContent] = useState(
		'Failed to save the changes to the server',
	);
	const [isError, setIsError] = useState(false);

	const [uploading, setUploading] = useState(false);

	const [successToastActive, setSuccessToastActive] = useState(false);
	const [successMessage, setSuccessMessage] = useState(
		'Changes saved successfully!',
	);

	const logoRef = useRef(null);

	const toggleToastActive = useCallback(
		() => setErrorToastActive((active) => !active),
		[],
	);

	const toggleSuccessToastActive = useCallback(
		() => setSuccessToastActive((active) => !active),
		[],
	);

	const onSelectFile = (e) => {
		const file = e.target.files[0];

		if (file.size > MAX_IMAGE_SIZE) {
			setErrorToastContent('Only images with less than 500KB are allowed!');
			setErrorToastActive(true);
			return;
		}

		if (!SUPPORTED_IMAGE_MIMETYPES.includes(file.type)) {
			setErrorToastContent('Please select an image file');
			setErrorToastActive(true);
			return;
		}

		uploadLogo(file);
	};

	async function loadClub() {
		try {
			const response: GetClubResponse = await http.get('/club', {
				params: {
					clubId,
				},
			});

			let s3Id = response.data.club.s3Id;
			if (!s3Id) s3Id = v4();
			setClubS3Id(s3Id);

			setClub({ ...response.data.club });
		} catch (error) {
			setIsError(true);
			setErrorToastContent('Something went wrong! Please try again.');
			setErrorToastActive(true);
			console.error('Failed to load club details from the server');
		}
	}

	async function uploadLogo(logoFile: File) {
		setUploading(true);
		try {
			const logoFormData = new FormData();
			logoFormData.append('logo', logoFile);

			const res: UploadFileResponse = await http.post(
				`/club/upload-logo/${clubS3Id}`,
				logoFormData,
			);
			setClub({ ...club, logo: res.data.key });
		} catch (error) {
			setIsError(true);
			setErrorToastContent('Something went wrong! Please try again.');
			setErrorToastActive(true);
			console.error('Failed to store the logo image in the server', error);
		} finally {
			setUploading(false);
		}
	}

	async function updateClub() {
		if (!isValid()) {
			return;
		}
		const payload = { ...club };
		setClub(null);
		try {
			await http.patch('/edit-club', { ...payload });
			setSuccessMessage('Changes saved successfully!');
			setSuccessToastActive(true);
		} catch (error) {
			setIsError(true);
			setErrorToastContent('Something went wrong! Please try again.');
			setErrorToastActive(true);
			console.error('Failed to save the club details to the server', error);
		} finally {
			loadClub();
		}
	}

	function isValid() {
		if (
			club.name.trim() === '' ||
			club.email.trim() === '' ||
			club.password.trim() === ''
		) {
			return false;
		}

		if (club.phone) {
			if (club.phone.length < 5) return false;
		}

		if (!isEmail(club.email)) {
			return false;
		}

		return true;
	}

	const errorToastMarkup = toastActive ? (
		<Toast content={errorToastContent} error onDismiss={toggleToastActive} />
	) : null;

	const successToastMarkup = successToastActive ? (
		<Toast content={successMessage} onDismiss={toggleSuccessToastActive} />
	) : null;

	useEffect(() => {
		loadClub();
		// eslint-disable-next-line
	}, []);

	return (
		<DefaultLayout>
			<Page
				primaryAction={{
					content: 'Save Changes',
					disabled: isError || !club || !isValid(),
					onAction: updateClub,
				}}
				title="Edit Club Details"
				breadcrumbs={[{ content: 'Club', url: `/clubs/${clubId}` }]}
			>
				{errorToastMarkup}
				{successToastMarkup}
				{isError ? (
					<ErrorBanner />
				) : club === null ? (
					<Loading />
				) : (
					<div style={{ minHeight: '70vh' }}>
						<Card title="Basic Details" sectioned>
							<FormLayout>
								<Thumbnail
									source={
										club.logo
											? SERVER_BASE_URL + '/get-club-logo/?key=' + club.logo
											: noLogoTemplate
									}
									alt="Add Logo"
									size="large"
								/>
								<button
									onClick={() => {
										logoRef.current.click();
									}}
									className="Polaris-Button"
								>
									<input
										type="file"
										id="logo"
										accept="image/*"
										ref={logoRef}
										onChange={onSelectFile}
										style={{ display: 'none' }}
									/>
									{uploading ? <Spinner size="small" /> : 'Select Logo'}
								</button>
								<TextField
									value={club.name}
									onChange={(name) => setClub({ ...club, name })}
									label="Club Name"
									type="text"
									autoComplete="text"
									requiredIndicator
								/>
								<FormLayout.Group>
									<TextField
										value={club.email}
										onChange={(email) => setClub({ ...club, email })}
										label="Email"
										type="email"
										autoComplete="email"
										requiredIndicator
									/>
									<TextField
										value={club.phone}
										onChange={(phone) => setClub({ ...club, phone })}
										label="Phone"
										type="number"
										autoComplete="phone"
									/>
								</FormLayout.Group>
								<TextField
									value={club.password}
									onChange={(password) => setClub({ ...club, password })}
									label="Password"
									type="password"
									autoComplete="password"
									requiredIndicator
								/>
							</FormLayout>
						</Card>
						<Card title="Personnel Info" sectioned>
							<FormLayout>
								<FormLayout.Group>
									<TextField
										value={club.chairman}
										onChange={(chairman) => setClub({ ...club, chairman })}
										label="Chairman"
										type="text"
										autoComplete="text"
									/>
									<TextField
										value={club.president}
										onChange={(president) => setClub({ ...club, president })}
										label="President"
										type="text"
										autoComplete="text"
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<TextField
										value={club.generalSecretary}
										onChange={(generalSecretary) =>
											setClub({ ...club, generalSecretary })
										}
										label="General Secretary"
										type="text"
										autoComplete="text"
									/>
									<TextField
										value={club.treasurer}
										onChange={(treasurer) => setClub({ ...club, treasurer })}
										label="Treasurer"
										type="text"
										autoComplete="text"
									/>
									<TextField
										value={club.teamManager}
										onChange={(teamManager) =>
											setClub({ ...club, teamManager })
										}
										label="Team Manager"
										type="text"
										autoComplete="text"
									/>
								</FormLayout.Group>
							</FormLayout>
						</Card>
					</div>
				)}{' '}
			</Page>
		</DefaultLayout>
	);
}
