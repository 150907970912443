import { useCallback, useState } from 'react';
import { TopBar } from '@shopify/polaris';
import useAuthContext from '../../utils/hooks/useAuthContext.hook';
import { LogOutMinor } from '@shopify/polaris-icons';

const LOCAL_STORAGE_KEY = 'difa-app-token';

export default function TopBarMain({ onNavigationToggle }) {
	const { user } = useAuthContext();

	const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

	const toggleIsUserMenuOpen = useCallback(
		() => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
		[],
	);

	const userMenuMarkup = (
		<TopBar.UserMenu
			name={user?.name || 'Admin'}
			detail={user?.email || 'admin@difaksa.com'}
			initials={user?.name?.charAt(0) || 'A'}
			open={isUserMenuOpen}
			onToggle={toggleIsUserMenuOpen}
			actions={[
				{
					items: [
						{
							content: 'Logout',
							icon: LogOutMinor,
							onAction: () => {
								localStorage.setItem(LOCAL_STORAGE_KEY, '');
								window.location.pathname = '/';
							},
						},
					],
				},
			]}
		/>
	);

	return (
		<TopBar
			showNavigationToggle
			userMenu={userMenuMarkup}
			onNavigationToggle={onNavigationToggle}
		/>
	);
}
