import {
	Badge,
	Banner,
	Card,
	Icon,
	Layout,
	Loading,
	Page,
	ResourceItem,
	ResourceList,
	TextStyle,
	Thumbnail,
	Toast,
} from '@shopify/polaris';
import { ExternalMinor } from '@shopify/polaris-icons';
import { AxiosInstance } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import DefaultLayout from '../../layout/default';
import { ErrorBanner } from '../../utils/components/ErrorBanner';
import {
	LOCAL_STORAGE_KEY,
	SERVER_BASE_URL,
	STATUSES,
	STATUSES_STYLE,
} from '../../utils/constants';
import useAxios from '../../utils/hooks/useAxios.hook';
import {
	GetPlayerResponse,
	PlayerFullDetails,
	PlayerURLParams,
} from '../../utils/types';
import avatar from '../../assets/images/avatar.png';

export function Player() {
	const { registrationNumber } = useParams<PlayerURLParams>();
	const [player, setPlayer] = useState<PlayerFullDetails>(null);

	const token = localStorage.getItem(LOCAL_STORAGE_KEY);
	const http: AxiosInstance = useAxios(token);

	const [toastActive, setToastActive] = useState(false);
	const [isError, setIsError] = useState(false);

	const toggleToastActive = useCallback(
		() => setToastActive((active) => !active),
		[],
	);

	async function loadPlayer() {
		try {
			const response: GetPlayerResponse = await http.get('/player', {
				params: {
					registrationNumber,
				},
			});

			setPlayer({ ...response.data.player });
		} catch (error) {
			setIsError(true);
			setToastActive(true);
			console.error('Failed to load player details from the server');
		}
	}

	const toastMarkup = toastActive ? (
		<Toast
			content="Failed to load player details from the server. Please try again."
			error
			onDismiss={toggleToastActive}
		/>
	) : null;

	async function changePlayerActiveStatus() {
		const payload = { ...player, isActive: !player.isActive };

		setPlayer(null);
		try {
			await http.patch('/edit-player', { ...payload });
		} catch (error) {
			setIsError(true);
			console.error('Failed to save the club details to the server', error);
		} finally {
			loadPlayer();
		}
	}

	useEffect(() => {
		loadPlayer();
		// eslint-disable-next-line
	}, []);

	return (
		<DefaultLayout>
			<Page
				primaryAction={{
					content: 'Edit Player',
					disabled: isError || !player,
					url: `/players/${registrationNumber}/edit`,
				}}
				secondaryActions={[
					{
						content:
							player && player.isActive
								? 'Make Player Inactive'
								: 'Make Player Active',
						disabled: player === null,
						onAction: changePlayerActiveStatus,
					},
				]}
				title="Player Details"
				breadcrumbs={[{ content: 'Player', url: '/players' }]}
			>
				{toastMarkup}
				{isError ? (
					<ErrorBanner />
				) : player === null ? (
					<Loading />
				) : (
					<div style={{ minHeight: '70vh' }}>
						<Card
							title={
								<Layout>
									<Layout.Section>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
											}}
										>
											<Thumbnail
												alt=""
												source={
													player.photo
														? SERVER_BASE_URL +
														  '/get-player-image/?key=' +
														  player.photo
														: avatar
												}
											/>
											<h2
												className="Polaris-Heading"
												style={{ paddingLeft: '15px' }}
											>
												{player.fullName}
											</h2>
											<div style={{ marginLeft: '15px', display: 'flex' }}>
												<Badge status={STATUSES_STYLE[player.status]}>
													{STATUSES[player.status]}
												</Badge>
												<div style={{ marginLeft: '10px' }}>
													{player.isActive === true ? (
														<Badge status="success">Active</Badge>
													) : (
														<Badge status="new">Inactive</Badge>
													)}
												</div>
											</div>
										</div>
									</Layout.Section>
								</Layout>
							}
							sectioned
						>
							<Layout>
								<Layout.Section>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											columnGap: '20px',
											width: '100%',
											height: '100%',
											justifyContent: 'stretch',
										}}
									>
										<div style={{ width: '100%' }}>
											<Card title="DIFA Registration Number" sectioned>
												<TextStyle variation="strong">
													{player.registrationNumber}
												</TextStyle>
											</Card>
										</div>
										<div style={{ width: '100%' }}>
											<Card title="Club" sectioned>
												<TextStyle variation="strong">
													{player.club ? (
														<a
															href={`/clubs/${player.club?.clubId}`}
															style={{ textDecoration: 'none' }}
														>
															{player.club.name}
														</a>
													) : (
														<Banner status="warning">
															Player is not currently assigned to any club.
														</Banner>
													)}
												</TextStyle>
											</Card>
										</div>
									</div>
								</Layout.Section>
							</Layout>
						</Card>
						<Card title="Personal Details" sectioned>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									columnGap: '20px',
									width: '100%',
									height: '100%',
									justifyContent: 'stretch',
								}}
							>
								<div style={{ width: '100%' }}>
									<Card.Section title="Email">
										<TextStyle variation="strong">
											{player.email || '-'}
										</TextStyle>
									</Card.Section>
								</div>
								<div style={{ width: '100%' }}>
									<Card.Section title="Phone">
										<TextStyle variation="strong">
											{player.phone || '-'}
										</TextStyle>
									</Card.Section>
								</div>
								<div style={{ width: '100%' }}>
									<Card.Section title="Date of Birth">
										<TextStyle variation="strong">
											{player.dateOfBirth || '-'}
										</TextStyle>
									</Card.Section>
								</div>
							</div>
							<div
								style={{
									paddingTop: '20px',
									display: 'flex',
									flexDirection: 'row',
									columnGap: '20px',
									width: '100%',
									height: '100%',
									justifyContent: 'stretch',
								}}
							>
								<div style={{ width: '100%' }}>
									<Card.Section title="Blood Group">
										<TextStyle variation="strong">
											{player.bloodGroup || '-'}
										</TextStyle>
									</Card.Section>
								</div>
								<div style={{ width: '100%' }}>
									<Card.Section title="Iqama Number">
										<TextStyle variation="strong">
											{player.iqamaNumber ? player.iqamaNumber : '-'}
										</TextStyle>
									</Card.Section>
								</div>
								<div style={{ width: '100%' }}>
									<Card.Section title="Passport Number">
										<TextStyle variation="strong">
											{player.passportNumber ? player.passportNumber : '-'}
										</TextStyle>
									</Card.Section>
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									columnGap: '20px',
									width: '100%',
									height: '100%',
									justifyContent: 'stretch',
								}}
							>
								<div style={{ width: '100%' }}>
									<Card.Section title="Home Address (India)">
										<TextStyle variation="strong">
											{player.homeAddress || '-'}
										</TextStyle>
									</Card.Section>
								</div>
								<div style={{ width: '100%' }}>
									<Card.Section title="Saudi Address">
										<TextStyle variation="strong">
											{player.saudiAddress || '-'}
										</TextStyle>
									</Card.Section>
								</div>
								<div style={{ width: '100%' }}>
									<Card.Section title="Company Name">
										<TextStyle variation="strong">
											{player.companyName || '-'}
										</TextStyle>
									</Card.Section>
								</div>
							</div>
						</Card>
						<Card sectioned title="Player Documents">
							<ResourceList
								resourceName={{ singular: 'document', plural: 'documents' }}
								items={[
									{
										id: 'registrationForm',
										url: player.registrationForm,
										name: 'DIFA Registration Form',
									},
									{
										id: 'affidavit',
										url: player.affidavit,
										name: 'Affidavit',
									},
									{
										id: 'passport',
										url: player.passport,
										name: 'Passport',
									},
									{
										id: 'iqama',
										url: player.iqama,
										name: 'Iqama / Saudi Visa Copy',
									},
								]}
								renderItem={(item) => {
									const { id, url, name } = item;

									return (
										<ResourceItem
											id={id}
											url={
												url
													? SERVER_BASE_URL +
													  `/admin/get-player-document?token=${token}&key=${url}`
													: ''
											}
										>
											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div style={{ flex: '0.95' }}>
													{url ? (
														<TextStyle variation="strong">{name}</TextStyle>
													) : (
														<TextStyle variation="strong">
															{name} - No file uploaded
														</TextStyle>
													)}
												</div>
												<div style={{ flex: '0.05' }}>
													<Icon source={ExternalMinor} />
												</div>
											</div>
										</ResourceItem>
									);
								}}
							/>
						</Card>
					</div>
				)}
			</Page>
		</DefaultLayout>
	);
}
