import { Banner, FormLayout } from '@shopify/polaris';
import {
	APP_CONFIG_KEY,
	DEFAULT_CONFIG,
} from '../../utils/constants';
import { DocumentsConfig } from '../../utils/types';
import DocumentsSettingsForm from './DocumentsSettingsForm';
import FieldSettingsForm from './FieldSettingsForm';

interface IProps {
	loading: boolean;
	documentsConfig: DocumentsConfig;
	loadDocumentsConfig: any;
	setDocumentsConfig: any;
}
export default function RegistrationSettings({
	loading,
	documentsConfig,
	loadDocumentsConfig,
	setDocumentsConfig,
}: IProps) {
	const config =
		JSON.parse(localStorage.getItem(APP_CONFIG_KEY)) || DEFAULT_CONFIG;
	const showBanner = config.showBanner;

	return (
		<FormLayout>
			{showBanner && (
				<div style={{ padding: 20 }}>
					<Banner
						onDismiss={() => {
							localStorage.setItem(
								APP_CONFIG_KEY,
								JSON.stringify({ ...config, showBanner: false }),
							);
							loadDocumentsConfig();
						}}
					>
						<b>
							Enabled fields must be submitted for registering a new player for
							a club.
						</b>
					</Banner>
				</div>
			)}

			<FieldSettingsForm
				loading={loading}
				documentsConfig={documentsConfig}
				setDocumentsConfig={setDocumentsConfig}
			/>

			<DocumentsSettingsForm
				loading={loading}
				documentsConfig={documentsConfig}
				setDocumentsConfig={setDocumentsConfig}
			/>
		</FormLayout>
	);
}
