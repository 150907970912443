import { Card, Form, FormLayout, TextField, Toast } from '@shopify/polaris';
import { AxiosInstance } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { LOCAL_STORAGE_KEY } from '../../utils/constants';
import useAxios from '../../utils/hooks/useAxios.hook';
import { AdminDetails } from '../../utils/types';
import { isEmail } from '../../utils/validations';

export function DifaOfficial({ setIsError }) {
	const [loading, setLoading] = useState(false);
	const [difaOfficial, setDifaOfficial] = useState<AdminDetails>(null);

	const token = localStorage.getItem(LOCAL_STORAGE_KEY);
	const http: AxiosInstance = useAxios(token);

	const [errorToastActive, setErrorToastActive] = useState(false);
	const [errorToastContent, setErrorToastContent] = useState(
		'Failed to save the changes to the server',
	);
	const toggleToastActive = useCallback(
		() => setErrorToastActive((active) => !active),
		[],
	);

	const [successToastActive, setSuccessToastActive] = useState(false);
	const [successMessage, setSuccessMessage] = useState(
		'Changes saved successfully!',
	);
	const toggleSuccessToastActive = useCallback(
		() => setSuccessToastActive((active) => !active),
		[],
	);

	function isValid() {
		if (!difaOfficial) {
			return;
		}

		if (
			difaOfficial.email.trim() === '' ||
			difaOfficial.password.trim() === ''
		) {
			return false;
		}

		if (!isEmail(difaOfficial.email)) {
			return false;
		}

		return true;
	}

	async function loadDifaOfficialUser() {
		setLoading(true);
		try {
			const res: any = await http.get('/difa-official');
			setDifaOfficial(res.data.difaOfficial);
		} catch (err) {
			setErrorToastContent('Failed to load data from the server');
			setErrorToastActive(true);
			setIsError(true);
			console.error(
				'Failed to load DIFA Official user details from the server',
			);
		} finally {
			setLoading(false);
		}
	}

	async function updateDifaOffidialUser() {
		if (!isValid()) {
			return;
		}
		const payload = { ...difaOfficial };
		setLoading(true);
		try {
			await http.patch('/update-difa-official', { ...payload });
			setSuccessMessage('Changes saved successfully!');
			setSuccessToastActive(true);
		} catch (error) {
			setErrorToastContent('Something went wrong! Please try again.');
			setErrorToastActive(true);
			console.error(
				'Failed to save the registration desk user details to the server',
				error,
			);
		} finally {
			loadDifaOfficialUser();
		}
	}

	useEffect(() => {
		loadDifaOfficialUser();
		// eslint-disable-next-line
	}, []);

	const errorToastMarkup = errorToastActive ? (
		<Toast content={errorToastContent} error onDismiss={toggleToastActive} />
	) : null;

	const successToastMarkup = successToastActive ? (
		<Toast content={successMessage} onDismiss={toggleSuccessToastActive} />
	) : null;

	return (
		<Card
			title="DIFA Official User Settings"
			sectioned
			primaryFooterAction={{
				content: 'Save Changes',
				disabled: loading || !isValid(),
				onAction: updateDifaOffidialUser,
			}}
		>
			{successToastMarkup}
			{errorToastMarkup}
			<Form onSubmit={() => {}}>
				<FormLayout>
					<TextField
						label="Email"
						type="email"
						disabled={loading}
						value={difaOfficial ? difaOfficial.email : 'official@difaksa.com'}
						onChange={(email) => {
							setDifaOfficial({ ...difaOfficial, email });
						}}
						placeholder="Email address used for the DIFA Official user for login"
						clearButton
						onClearButtonClick={() => {
							setDifaOfficial({ ...difaOfficial, email: '' });
						}}
					/>
					<TextField
						label="Password"
						type="password"
						disabled={loading}
						value={
							difaOfficial ? difaOfficial.password : '*********************'
						}
						onChange={(password) => {
							setDifaOfficial({ ...difaOfficial, password });
						}}
						placeholder="Enter the password for the DIFA Official user"
						clearButton
						onClearButtonClick={() => {
							setDifaOfficial({ ...difaOfficial, password: '' });
						}}
					/>
				</FormLayout>
			</Form>
		</Card>
	);
}
