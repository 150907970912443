import {
	Card,
	FormLayout,
	Loading,
	Page,
	Spinner,
	TextField,
	Thumbnail,
	Toast,
} from '@shopify/polaris';
import { AxiosInstance } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import DefaultLayout from '../../layout/default';
import { ErrorBanner } from '../../utils/components/ErrorBanner';
import {
	LOCAL_STORAGE_KEY,
	MAX_IMAGE_SIZE,
	SERVER_BASE_URL,
	SUPPORTED_IMAGE_MIMETYPES,
} from '../../utils/constants';
import { isEmail } from '../../utils/validations';
import useAxios from '../../utils/hooks/useAxios.hook';
import { Redirect } from 'react-router-dom';
import { UploadFileResponse } from '../../utils/types';
import { v4 } from 'uuid';
import noLogoTemplate from '../../assets/images/noLogoTemplate.jpeg';

export function CreateClub() {
	const [club, setClub] = useState({
		name: '',
		email: '',
		logo: '',
		phone: '',
		password: '',
		chairman: '',
		president: '',
		generalSecretary: '',
		treasurer: '',
		teamManager: '',
	});
	const [s3Id, setS3Id] = useState<string>();

	const logoRef = useRef(null);

	const token = localStorage.getItem(LOCAL_STORAGE_KEY);
	const http: AxiosInstance = useAxios(token);

	const [toastContent, setToastContent] = useState(
		'Failed to save the club details to the server',
	);
	const [toastActive, setToastActive] = useState(false);
	const [isError, setIsError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [uploading, setUploading] = useState(false);

	const toggleToastActive = useCallback(
		() => setToastActive((active) => !active),
		[],
	);

	const onSelectFile = (e) => {
		const file = e.target.files[0];

		if (file.size > MAX_IMAGE_SIZE) {
			setToastContent('Only images with less than 500KB are allowed!');
			setToastActive(true);
			return;
		}

		if (!SUPPORTED_IMAGE_MIMETYPES.includes(file.type)) {
			setToastContent('Please select an image file');
			setToastActive(true);
			return;
		}

		uploadLogo(file);
	};

	async function createClub() {
		if (!isValid()) {
			return;
		}
		setLoading(true);
		const payload = { ...club, s3Id };
		try {
			await http.post('/create-club', { ...payload });
			window.location.replace('/clubs');
			return <Redirect to={'/clubs'} />;
		} catch (error) {
			if (error.response && error.response.status === 409) {
				setToastContent('Another club with this email already exists!');
				setToastActive(true);
			} else {
				setIsError(true);
				setToastContent('Something went wrong! Please try again.');
				setToastActive(true);
				console.error('Failed to save the club details to the server', error);
			}
		} finally {
			setLoading(false);
		}
	}

	async function uploadLogo(logoFile: File) {
		setUploading(true);
		try {
			const logoFormData = new FormData();
			logoFormData.append('logo', logoFile);

			const res: UploadFileResponse = await http.post(
				`/club/upload-logo/${s3Id}`,
				logoFormData,
			);

			setClub({ ...club, logo: res.data.key });
		} catch (error) {
			setIsError(true);
			setToastContent('Something went wrong! Please try again.');
			setToastActive(true);
			console.error('Failed to store the logo image in the server', error);
		} finally {
			setUploading(false);
		}
	}

	function isValid() {
		if (
			club.name.trim() === '' ||
			club.email.trim() === '' ||
			club.phone.trim() === '' ||
			club.password.trim() === ''
		) {
			return false;
		}

		if (!isEmail(club.email)) {
			return false;
		}

		if (club.phone.length < 5) {
			return false;
		}

		return true;
	}

	const toastMarkup = toastActive ? (
		<Toast content={toastContent} error onDismiss={toggleToastActive} />
	) : null;

	useEffect(() => {
		setS3Id(v4());
	}, []);

	return (
		<DefaultLayout>
			<Page
				primaryAction={{
					content: 'Save Club',
					disabled: isError || loading || !isValid(),
					onAction: createClub,
				}}
				title="Add New Club"
				breadcrumbs={[{ content: 'Club', url: `/clubs/` }]}
			>
				{toastMarkup}
				{isError ? (
					<ErrorBanner />
				) : loading ? (
					<Loading />
				) : (
					<div style={{ minHeight: '70vh' }}>
						<Card title="Basic Details" sectioned>
							<FormLayout>
								<Thumbnail
									source={
										club.logo
											? SERVER_BASE_URL + '/get-club-logo/?key=' + club.logo
											: noLogoTemplate
									}
									alt="Add Logo"
									size="large"
								/>
								<button
									onClick={() => {
										logoRef.current.click();
									}}
									className="Polaris-Button"
								>
									<input
										type="file"
										id="logo"
										accept="image/*"
										ref={logoRef}
										onChange={onSelectFile}
										style={{ display: 'none' }}
									/>
									{uploading ? <Spinner size="small" /> : 'Select Logo'}
								</button>
								<TextField
									value={club.name}
									onChange={(name) => setClub({ ...club, name })}
									label="Club Name"
									type="text"
									autoComplete="text"
									placeholder="Please enter a name for the new club"
									requiredIndicator
								/>
								<FormLayout.Group>
									<TextField
										value={club.email}
										onChange={(email) => setClub({ ...club, email })}
										label="Email"
										type="email"
										autoComplete="email"
										placeholder="The email address used for login and notifications"
										requiredIndicator
									/>
									<TextField
										value={club.phone}
										onChange={(phone) => setClub({ ...club, phone })}
										label="Phone"
										type="number"
										autoComplete="phone"
										placeholder="Contact phone number for the club user"
										requiredIndicator
									/>
								</FormLayout.Group>
								<TextField
									value={club.password}
									onChange={(password) => setClub({ ...club, password })}
									label="Password"
									type="password"
									autoComplete="password"
									placeholder="Please enter a strong password"
									requiredIndicator
								/>
							</FormLayout>
						</Card>
						<Card title="Personnel Info" sectioned>
							<FormLayout>
								<FormLayout.Group>
									<TextField
										value={club.chairman}
										onChange={(chairman) => setClub({ ...club, chairman })}
										label="Chairman"
										type="text"
										autoComplete="text"
									/>
									<TextField
										value={club.president}
										onChange={(president) => setClub({ ...club, president })}
										label="President"
										type="text"
										autoComplete="text"
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<TextField
										value={club.generalSecretary}
										onChange={(generalSecretary) =>
											setClub({ ...club, generalSecretary })
										}
										label="General Secretary"
										type="text"
										autoComplete="text"
									/>
									<TextField
										value={club.treasurer}
										onChange={(treasurer) => setClub({ ...club, treasurer })}
										label="Treasurer"
										type="text"
										autoComplete="text"
									/>
									<TextField
										value={club.teamManager}
										onChange={(teamManager) =>
											setClub({ ...club, teamManager })
										}
										label="Team Manager"
										type="text"
										autoComplete="text"
									/>
								</FormLayout.Group>
							</FormLayout>
						</Card>
					</div>
				)}
			</Page>
		</DefaultLayout>
	);
}
