import {
	Badge,
	Card,
	EmptyState,
	Pagination,
	ResourceItem,
	ResourceList,
	Tabs,
	TextField,
	TextStyle,
	Toast,
} from '@shopify/polaris';
import { AxiosInstance } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { ErrorBanner } from '../../utils/components/ErrorBanner';
import {
	TRANSFERS_LIMIT,
	LOCAL_STORAGE_KEY,
	STATUSES_STYLE,
	TRANSFER_STATUSES,
} from '../../utils/constants';
import useAxios from '../../utils/hooks/useAxios.hook';
import { TransferListItem } from '../../utils/types';
import moment from 'moment';

export default function TransfersList() {
	const [transfers, setTransfers] = useState([]);
	const [count, setCount] = useState(0);

	const [searchText, setSearchText] = useState('');
	const [selectedTab, setSelectedTab] = useState(0);

	const [loading, setLoading] = useState(false);
	const [toastActive, setToastActive] = useState(false);
	const [isError, setIsError] = useState(false);
	const [offset, setOffset] = useState(0);

	const toggleToastActive = useCallback(
		() => setToastActive((active) => !active),
		[],
	);

	const token = localStorage.getItem(LOCAL_STORAGE_KEY);
	const http: AxiosInstance = useAxios(token);

	async function getTransfers() {
		setIsError(false);
		setLoading(true);

		const statusFilter = tabs[selectedTab].panelID;

		try {
			const response: any = await http.get('/transfers', {
				params: {
					limit: TRANSFERS_LIMIT,
					offset,
					searchText,
					statusFilter,
				},
			});
			const playerTransfers = response.data.transfers.rows;

			setTransfers([...playerTransfers]);
			setCount(response.data.transfers.count);
			setIsError(false);
			window.scrollTo(0, 0);
		} catch (error) {
			setToastActive(true);
			console.error('Failed to load all players from the server', error);
			setIsError(true);
		} finally {
			setLoading(false);
		}
	}

	const toastMarkup = toastActive ? (
		<Toast
			content="Failed to load all players from the server. Please try again."
			error
			onDismiss={toggleToastActive}
		/>
	) : null;

	const emptyStateMarkup = isError ? (
		<div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
			<ErrorBanner />
		</div>
	) : !transfers.length ? (
		<EmptyState
			heading="No transfers to show"
			image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
		>
			<p>
				{searchText === ''
					? 'All registered players will be shown here'
					: 'No items matching your search or filter.'}
			</p>
		</EmptyState>
	) : undefined;

	const pagination = (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignContent: 'center',
			}}
		>
			{loading || !transfers.length ? (
				''
			) : (
				<div style={{ marginTop: '10px' }}>
					<TextStyle variation="strong">
						{offset + 1} -
						{offset + TRANSFERS_LIMIT > count ? count : offset + TRANSFERS_LIMIT} of{' '}
						{count}
					</TextStyle>
				</div>
			)}
			<div style={{ marginLeft: 10 }}>
				<Pagination
					hasPrevious={!(offset === 0) && !loading}
					onPrevious={() => {
						setOffset(offset - TRANSFERS_LIMIT);
					}}
					hasNext={offset + transfers.length < count && !loading}
					onNext={() => {
						setOffset(offset + TRANSFERS_LIMIT);
					}}
				/>
			</div>
		</div>
	);

	useEffect(() => {
		getTransfers();
		// eslint-disable-next-line
	}, [offset]);

	useEffect(() => {
		setOffset(0);
		setSearchText('');
		getTransfers();
		// eslint-disable-next-line
	}, [selectedTab]);

	function handleEnterKeyPress(event) {
		const enterKeyPressed = event.keyCode === 13;
		if (enterKeyPressed) {
			event.preventDefault();
			setOffset(0);
			getTransfers();
		}
	}

	const handleTabChange = useCallback(
		(selectedTabIndex) => setSelectedTab(selectedTabIndex),
		[],
	);

	const renderItem = (item: TransferListItem) => {
		const {
			transferId,
			playerRegistrationNumber,
			playerName,
			fromClubName,
			toClubName,
			status,
			createdAt,
		} = item;

		return (
			<ResourceItem url={`/transfers/${transferId}`} id={createdAt}>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ flex: '1', verticalAlign: 'bottom' }}>
						<TextStyle variation="strong">{playerRegistrationNumber}</TextStyle>
					</div>
					<div style={{ flex: '1' }}>{playerName}</div>
					<div style={{ flex: '1' }}>{fromClubName || '-'}</div>
					<div style={{ flex: '1' }}>{toClubName || '-'}</div>
					<div style={{ flex: '1' }}>
						<Badge status={STATUSES_STYLE[status]}>{TRANSFER_STATUSES[status]}</Badge>
					</div>
					<div style={{ flex: '1' }}>
						{createdAt ? moment(createdAt).format('DD/MM/YYYY') : '-'}
					</div>
				</div>
			</ResourceItem>
		);
	};

	const tabs = [
		{
			id: 'all',
			content: 'All',
			accessibilityLabel: 'All players',
			panelID: '4',
		},
		{
			id: 'completed',
			content: 'Completed',
			panelID: '3',
		},
		{
			id: 'verified',
			content: 'Verified',
			panelID: '2',
		},
		{
			id: 'unattended',
			content: 'Pending',
			panelID: '0',
		},
		{
			id: 'onHold',
			content: 'On Hold',
			panelID: '1',
		},
	];

	return (
		<Card>
			<div style={loading || isError ? { pointerEvents: 'none' } : null}>
				<Tabs
					tabs={tabs}
					selected={selectedTab}
					onSelect={handleTabChange}
				></Tabs>
			</div>

			<div style={{ padding: '20px' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div style={{ flex: '0.8', verticalAlign: 'bottom' }}>
						<div onKeyDown={handleEnterKeyPress}>
							<TextField
								label=""
								value={searchText}
								onChange={(value) => {
									setSearchText(value);
								}}
								placeholder="Search using Register Number, Fullname or Email"
								clearButton
								onClearButtonClick={() => {
									setSearchText('');
								}}
								disabled={isError || loading}
							/>
						</div>
					</div>

					<div
						style={{
							flex: '0.2',
							paddingLeft: '10px',
							verticalAlign: 'center',
						}}
					>
						<button
							id="searchButton"
							disabled={loading || isError}
							className={
								loading || isError
									? 'Polaris-Button Polaris-Button--disabled'
									: 'Polaris-Button'
							}
							style={{ width: '100%' }}
							onClick={() => {
								setOffset(0);
								getTransfers();
							}}
						>
							Apply Search
						</button>
					</div>
				</div>

				<div
					style={{
						marginTop: 15,
						marginBottom: 15,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					{transfers.length > 50 && (
						<div style={{ marginLeft: 'auto' }}>{pagination}</div>
					)}
				</div>

				<div
					style={{
						padding: '20px 20px',
						borderBottom: '0.2rem solid #e1e3e5',
					}}
				>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<div style={{ flex: '1' }}>
							<TextStyle variation="strong">Register Number</TextStyle>
						</div>

						<div style={{ flex: '1' }}>
							<TextStyle variation="strong">Player</TextStyle>
						</div>

						<div style={{ flex: '1' }}>
							<TextStyle variation="strong">From</TextStyle>
						</div>

						<div style={{ flex: '1' }}>
							<TextStyle variation="strong">To</TextStyle>
						</div>

						<div style={{ flex: '1' }}>
							<TextStyle variation="strong">Status</TextStyle>
						</div>

						<div style={{ flex: '1' }}>
							<TextStyle variation="strong">Date</TextStyle>
						</div>
					</div>
				</div>

				<ResourceList
					emptyState={emptyStateMarkup}
					loading={loading}
					resourceName={{ singular: 'player', plural: 'players' }}
					items={transfers}
					renderItem={renderItem}
				/>

				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ marginLeft: 'auto' }}>{pagination}</div>
				</div>

				{toastMarkup}
			</div>
		</Card>
	);
}
