import { Banner } from '@shopify/polaris';

export function ErrorBanner() {
	return (
		<Banner
			status="critical"
			title="Could not connect with the server. Please try again."
		/>
	);
}
