import { Banner, Card, FormLayout, TextStyle } from '@shopify/polaris';
import { DocumentsConfig } from '../../utils/types';
import ToggleSwitch from '../Shared/ToggleSwitch';

interface IProps {
	loading: boolean;
	documentsConfig: DocumentsConfig;
	setDocumentsConfig: any;
}
export default function TransferSettings({
	loading,
	documentsConfig,
	setDocumentsConfig,
}: IProps) {
	const bannerClosed = localStorage.getItem('transfer_banner_closed') || null;

	return (
		<FormLayout>
			{(!bannerClosed || bannerClosed === 'false') && (
				<div style={{ padding: 20 }}>
					<Banner
						onDismiss={() => {
							localStorage.setItem('transfer_banner_closed', 'true');
						}}
					>
						<b>
							Player transfer window is only open to clubs and officials if it's
							enabled here!
						</b>
					</Banner>
				</div>
			)}

			<Card sectioned>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ display: 'flex', flex: '90' }}>
						<TextStyle variation="strong">
							Transfer Window (
							{documentsConfig?.transferOpen === true ? 'Enabled' : 'Disabled'})
						</TextStyle>
					</div>

					<ToggleSwitch
						disabled={loading}
						isToggled={documentsConfig && documentsConfig.transferOpen}
						onToggle={async () => {
							setDocumentsConfig({
								...documentsConfig,
								transferOpen: !documentsConfig.transferOpen,
							});
						}}
					/>
				</div>

				{documentsConfig?.transferOpen && (
					<div
						style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}
					>
						<div style={{ display: 'flex', flex: '90' }}>
							<TextStyle variation="strong">
								Transfer Document (
								{documentsConfig?.transferDocument === true
									? 'Enabled'
									: 'Disabled'}
								)
							</TextStyle>
						</div>

						<ToggleSwitch
							disabled={loading}
							isToggled={documentsConfig && documentsConfig.transferDocument}
							onToggle={async () => {
								setDocumentsConfig({
									...documentsConfig,
									transferDocument: !documentsConfig.transferDocument,
								});
							}}
						/>
					</div>
				)}
			</Card>
		</FormLayout>
	);
}
