import { Card, Page } from '@shopify/polaris';

import TransfersList from '../../components/Transfers/TransfersList';
import DefaultLayout from '../../layout/default';

export default function Transfers() {
	return (
		<DefaultLayout>
			<Page
				title="Transfers"
				fullWidth
			>
				<Card>
					<TransfersList />
				</Card>
			</Page>
		</DefaultLayout>
	);
}
