import { Card, Page, Tabs, Toast } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { DifaOfficial } from '../../components/Home/DifaOfficial';
import RegistrationSettings from '../../components/Home/RegistrationSettings';
import { RegistrationDesk } from '../../components/Home/RegistrationDesk';
import DefaultLayout from '../../layout/default';
import { ErrorBanner } from '../../utils/components/ErrorBanner';
import TransferSettings from '../../components/Home/TransferSettings';
import { DocumentsConfig } from '../../utils/types';
import useAxios from '../../utils/hooks/useAxios.hook';
import { LOCAL_STORAGE_KEY } from '../../utils/constants';

export function Home() {
	const token = localStorage.getItem(LOCAL_STORAGE_KEY);
	const http = useAxios(token);

	const [loading, setLoading] = useState(false);
	const [documentsConfig, setDocumentsConfig] = useState<DocumentsConfig>(null);

	const [isError, setIsError] = useState(false);

	const [errorToastActive, setErrorToastActive] = useState(false);
	const [errorToastContent, setErrorToastContent] = useState(
		'Failed to save the changes to the server',
	);
	const toggleToastActive = useCallback(
		() => setErrorToastActive((active) => !active),
		[],
	);

	const [successToastActive, setSuccessToastActive] = useState(false);
	const [successMessage, setSuccessMessage] = useState(
		'Changes saved successfully!',
	);
	const toggleSuccessToastActive = useCallback(
		() => setSuccessToastActive((active) => !active),
		[],
	);

	const [selectedTab, setSelectedTab] = useState(0);
	const handleTabChange = useCallback(
		(selectedTabIndex) => setSelectedTab(selectedTabIndex),
		[],
	);

	async function loadDocumentsConfig() {
		setLoading(true);
		try {
			const res: any = await http.get('/documents-config');
			setDocumentsConfig(res.data.documentsConfig);
		} catch (err) {
			setErrorToastContent('Failed to load data from the server');
			setErrorToastActive(true);
			setIsError(true);
			console.error('Failed to load configuration from the server');
		} finally {
			setLoading(false);
		}
	}

	async function saveDocumentsConfiguration() {
		if (!documentsConfig) {
			return;
		}

		setLoading(true);

		try {
			await http.patch('/save-documents-config', { ...documentsConfig });

			setSuccessMessage('Changes saved successfully!');
			setSuccessToastActive(true);
		} catch (err) {
			setErrorToastContent('Failed to save data to the server');
			setErrorToastActive(true);
			console.error('Failed to save configuration to the server');
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		loadDocumentsConfig();
		// eslint-disable-next-line
	}, [selectedTab]);

	useEffect(() => {
		saveDocumentsConfiguration();
		// eslint-disable-next-line
	}, [documentsConfig]);

	const tabs = [
		{
			id: 'users',
			content: <b>USERS</b>,
			panelID: 'users',
			component: (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						width: '100%',
						padding: 10,
					}}
				>
					<div style={{ flex: 1 }}>
						<RegistrationDesk setIsError={setIsError} />
					</div>

					<div style={{ paddingLeft: 10, flex: 1 }}>
						<DifaOfficial setIsError={setIsError} />
					</div>
				</div>
			),
		},
		{
			id: 'registration',
			content: <b>REGISTRATION</b>,
			panelID: 'registration',
			component: (
				<RegistrationSettings
					loading={loading}
					documentsConfig={documentsConfig}
					loadDocumentsConfig={loadDocumentsConfig}
					setDocumentsConfig={setDocumentsConfig}
				/>
			),
		},
		{
			id: 'transfer',
			content: <b>PLAYER TRANSFER</b>,
			panelID: 'transfer',
			component: (
				<TransferSettings
					loading={loading}
					documentsConfig={documentsConfig}
					setDocumentsConfig={setDocumentsConfig}
				/>
			),
		},
	];

	const errorToastMarkup = errorToastActive ? (
		<Toast content={errorToastContent} error onDismiss={toggleToastActive} />
	) : null;

	const successToastMarkup = successToastActive ? (
		<Toast content={successMessage} onDismiss={toggleSuccessToastActive} />
	) : null;

	return (
		<DefaultLayout>
			{successToastMarkup}
			{errorToastMarkup}

			<Page title="DIFA Admin Control Panel" fullWidth>
				{isError ? (
					<ErrorBanner />
				) : (
					<Card>
						<Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
							{tabs[selectedTab].component}
						</Tabs>
					</Card>
				)}
			</Page>
		</DefaultLayout>
	);
}
