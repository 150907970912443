import { Navigation } from '@shopify/polaris';
import {
	HomeMajor,
	SettingsMajor,
	CustomersMajor,
	CategoriesMajor,
	TransferMajor,
} from '@shopify/polaris-icons';
import { useLocation } from 'react-router';

export default function MainNav() {
	const location = useLocation();
	return (
		<Navigation location={location.pathname}>
			<Navigation.Section
				items={[
					{
						url: '/',
						label: 'Home',
						icon: HomeMajor,
						exactMatch: true,
					},
					{
						url: '/clubs',
						label: 'Clubs',
						icon: CategoriesMajor,
						exactMatch: false,
					},
					{
						url: '/players',
						label: 'Players',
						icon: CustomersMajor,
						exactMatch: false,
					},
					{
						url: '/transfers',
						label: 'Transfers',
						icon: TransferMajor,
						exactMatch: false,
					},
					{
						url: '/settings',
						label: 'Settings',
						icon: SettingsMajor,
					},
				]}
				fill={true}
			/>
		</Navigation>
	);
}
