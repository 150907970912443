import {
	Avatar,
	Badge,
	Card,
	EmptyState,
	Pagination,
	ResourceItem,
	ResourceList,
	Tabs,
	TextField,
	TextStyle,
	Toast,
} from '@shopify/polaris';
import { AxiosInstance } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { ErrorBanner } from '../../utils/components/ErrorBanner';
import {
	PLAYERS_LIMIT,
	LOCAL_STORAGE_KEY,
	SERVER_BASE_URL,
	STATUSES,
	STATUSES_STYLE,
} from '../../utils/constants';
import useAxios from '../../utils/hooks/useAxios.hook';
import { GetAllPlayersResponse, PlayerListItem } from '../../utils/types';
import avatar from '../../assets/images/avatar.png';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';

export default function PlayersList() {
	const [players, setPlayers] = useState([]);
	const [count, setCount] = useState(0);

	const [searchText, setSearchText] = useState('');
	const [selectedTab, setSelectedTab] = useState(0);

	const [loading, setLoading] = useState(false);
	const [toastActive, setToastActive] = useState(false);
	const [isError, setIsError] = useState(false);
	const [offset, setOffset] = useState(0);

	const [exporting, setExporting] = useState(false);

	const toggleToastActive = useCallback(
		() => setToastActive((active) => !active),
		[],
	);

	const token = localStorage.getItem(LOCAL_STORAGE_KEY);
	const http: AxiosInstance = useAxios(token);

	async function getPlayers() {
		setIsError(false);
		setLoading(true);

		const statusFilter = tabs[selectedTab].panelID;

		try {
			const response: GetAllPlayersResponse = await http.get('/all-players', {
				params: {
					limit: PLAYERS_LIMIT,
					offset,
					searchText,
					statusFilter,
				},
			});
			const allPlayers = response.data.players?.rows;
			setPlayers([...allPlayers]);
			setCount(response.data.players.count);
			setIsError(false);
			window.scrollTo(0, 0);
		} catch (error) {
			setToastActive(true);
			console.error('Failed to load all players from the server');
			setIsError(true);
		} finally {
			setLoading(false);
		}
	}

	async function handleExport() {
		setExporting(true);
		try {
			const response: any = await http.get('/export-players', {});
			const players = response.data.players;

			const workbook = new Workbook();
			const worksheet = workbook.addWorksheet('All Players');

			worksheet.columns = [
				{ header: 'Player ID', key: 'playerId', width: 10 },
				{ header: 'Club ID', key: 'clubId', width: 10 },
				{ header: 'Reg Number', key: 'registrationNumber', width: 10 },
				{ header: 'Full Name', key: 'fullName', width: 32 },
				{ header: 'Email', key: 'email', width: 32 },
				{ header: 'Mobile Number', key: 'phone', width: 32 },
				{ header: 'Date of Birth', key: 'dateOfBirth', width: 15 },
				{ header: 'Blood Group', key: 'bloodGroup', width: 15 },
				{ header: 'Home Address (India)', key: 'homeAddress', width: 40 },
				{ header: 'Saudi Address', key: 'homeAddress', width: 40 },
				{ header: 'Company Name', key: 'companyName', width: 12 },
				{ header: 'Passport Number', key: 'passportNumber', width: 12 },
				{ header: 'Iqama Number', key: 'iqamaNumber', width: 12 },
				{ header: 'Is Active', key: 'isActive', width: 12 },
			];

			worksheet.addRows(players.rows);

			const buf = await workbook.xlsx.writeBuffer();

			saveAs(new Blob([buf]), 'AllPlayers.xlsx');
		} catch (err) {
			setToastActive(true);
			console.error('Failed to load all players from the server', err);
			setIsError(true);
		} finally {
			setExporting(false);
		}
	}

	const toastMarkup = toastActive ? (
		<Toast
			content="Failed to load all players from the server. Please try again."
			error
			onDismiss={toggleToastActive}
		/>
	) : null;

	const emptyStateMarkup = isError ? (
		<div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
			<ErrorBanner />
		</div>
	) : !players.length ? (
		<EmptyState
			heading="No players to show"
			image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
		>
			<p>
				{searchText === ''
					? 'All registered players will be shown here'
					: 'No items matching your search or filter.'}
			</p>
		</EmptyState>
	) : undefined;

	const pagination = (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignContent: 'center',
			}}
		>
			{loading || !players.length ? (
				''
			) : (
				<div style={{ marginTop: '10px' }}>
					<TextStyle variation="strong">
						{offset + 1} -
						{offset + PLAYERS_LIMIT > count ? count : offset + PLAYERS_LIMIT} of{' '}
						{count}
					</TextStyle>
				</div>
			)}
			<div style={{ marginLeft: 10 }}>
				<Pagination
					hasPrevious={!(offset === 0) && !loading}
					onPrevious={() => {
						setOffset(offset - PLAYERS_LIMIT);
					}}
					hasNext={offset + players.length < count && !loading}
					onNext={() => {
						setOffset(offset + PLAYERS_LIMIT);
					}}
				/>
			</div>
		</div>
	);

	useEffect(() => {
		getPlayers();
		// eslint-disable-next-line
	}, [offset]);

	useEffect(() => {
		setOffset(0);
		setSearchText('');
		getPlayers();
		// eslint-disable-next-line
	}, [selectedTab]);

	function handleEnterKeyPress(event) {
		const enterKeyPressed = event.keyCode === 13;
		if (enterKeyPressed) {
			event.preventDefault();
			setOffset(0);
			getPlayers();
		}
	}

	const handleTabChange = useCallback(
		(selectedTabIndex) => setSelectedTab(selectedTabIndex),
		[],
	);

	const renderItem = (item: PlayerListItem) => {
		const {
			playerId,
			registrationNumber,
			fullName,
			email,
			iqamaNumber,
			photo,
			status,
		} = item;

		const media = (
			<Avatar
				source={
					photo ? SERVER_BASE_URL + '/get-player-image/?key=' + photo : avatar
				}
				size="medium"
				name={fullName}
			/>
		);

		return (
			<ResourceItem
				url={`/players/${registrationNumber}`}
				id={playerId}
				media={media}
			>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ flex: '1', verticalAlign: 'bottom' }}>
						<TextStyle variation="strong">{registrationNumber}</TextStyle>
					</div>
					<div style={{ flex: '1' }}>{fullName}</div>
					<div style={{ flex: '1' }}>{email || '-'}</div>
					<div style={{ flex: '1' }}>{iqamaNumber || '-'}</div>
					<div style={{ flex: '1' }}>
						<Badge status={STATUSES_STYLE[status]}>{STATUSES[status]}</Badge>
					</div>
				</div>
			</ResourceItem>
		);
	};

	const tabs = [
		{
			id: 'all',
			content: 'All',
			accessibilityLabel: 'All players',
			panelID: '4',
		},
		{
			id: 'approved',
			content: 'Approved',
			panelID: '3',
		},
		{
			id: 'verified',
			content: 'Verified',
			panelID: '2',
		},
		{
			id: 'unattended',
			content: 'Pending',
			panelID: '0',
		},
		{
			id: 'onHold',
			content: 'On Hold',
			panelID: '1',
		},
	];

	return (
		<Card>
			<div style={loading || isError ? { pointerEvents: 'none' } : null}>
				<Tabs
					tabs={tabs}
					selected={selectedTab}
					onSelect={handleTabChange}
				></Tabs>
			</div>

			<div style={{ padding: '20px' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div style={{ flex: '0.8', verticalAlign: 'bottom' }}>
						<div onKeyDown={handleEnterKeyPress}>
							<TextField
								label=""
								value={searchText}
								onChange={(value) => {
									setSearchText(value);
								}}
								placeholder="Search using Register Number, Fullname or Email"
								clearButton
								onClearButtonClick={() => {
									setSearchText('');
								}}
								disabled={isError || loading}
							/>
						</div>
					</div>
					<div
						style={{
							flex: '0.2',
							paddingLeft: '10px',
							verticalAlign: 'center',
						}}
					>
						<button
							id="searchButton"
							disabled={loading || isError}
							className={
								loading || isError
									? 'Polaris-Button Polaris-Button--disabled'
									: 'Polaris-Button'
							}
							style={{ width: '100%' }}
							onClick={() => {
								setOffset(0);
								getPlayers();
							}}
						>
							Apply Search
						</button>
					</div>
				</div>
				<div
					style={{
						marginTop: 15,
						marginBottom: 15,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<button
						id="exportButton"
						disabled={loading || isError || exporting}
						className={
							loading || isError || exporting
								? 'Polaris-Button Polaris-Button--primary Polaris-Button--disabled'
								: 'Polaris-Button Polaris-Button--primary'
						}
						onClick={handleExport}
					>
						{exporting ? 'Exporting...' : 'Export to Excel'}
					</button>
					{players.length > 50 && (
						<div style={{ marginLeft: 'auto' }}>{pagination}</div>
					)}
				</div>
				<div
					style={{
						padding: '20px 20px',
						borderBottom: '0.2rem solid #e1e3e5',
					}}
				>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<div style={{ flex: '0.25' }}>
							<TextStyle variation="strong"> </TextStyle>
						</div>
						<div style={{ flex: '1' }}>
							<TextStyle variation="strong">Register Number</TextStyle>
						</div>
						<div style={{ flex: '1' }}>
							<TextStyle variation="strong">Full Name</TextStyle>
						</div>
						<div style={{ flex: '1' }}>
							<TextStyle variation="strong">Email</TextStyle>
						</div>
						<div style={{ flex: '1' }}>
							<TextStyle variation="strong">Iqama Number</TextStyle>
						</div>
						<div style={{ flex: '1' }}>
							<TextStyle variation="strong">Verification Status</TextStyle>
						</div>
					</div>
				</div>
				<ResourceList
					emptyState={emptyStateMarkup}
					loading={loading}
					resourceName={{ singular: 'player', plural: 'players' }}
					items={players}
					renderItem={renderItem}
				/>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ marginLeft: 'auto' }}>{pagination}</div>
				</div>
				{toastMarkup}
			</div>
		</Card>
	);
}
