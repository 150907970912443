import './ToggleSwtich.css';

interface IProps {
	isToggled: boolean;
	onToggle: any;
	disabled?: boolean;
}

function ToggleSwitch({ isToggled, onToggle, disabled }: IProps) {
	return (
		<label className="toggle-switch">
			<input
				type="checkbox"
				disabled={disabled}
				checked={isToggled}
				onChange={onToggle}
			/>
			<span className="switch" />
		</label>
	);
}
export default ToggleSwitch;
