import axios, { AxiosInstance } from 'axios';
import { LOCAL_STORAGE_KEY, SERVER_BASE_URL } from '../constants';

const createAxiosInstance = (token): AxiosInstance => {
	const instance = axios.create({
		baseURL: `${SERVER_BASE_URL}/admin`,
		timeout: 60000,
		responseType: 'json',
		headers: {
			'Content-type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}) as AxiosInstance;

	instance.interceptors.response.use(
		(response) => response,
		async (error) => {
			if (error?.response?.status === 401) {
				localStorage.setItem(LOCAL_STORAGE_KEY, '');
				window.location.pathname = '/';
			}

			throw error;
		},
	);

	return instance;
};

export default createAxiosInstance;
