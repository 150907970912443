import { AxiosInstance } from 'axios';
import { useEffect } from 'react';
import createAxiosInstance from '../helpers/api';

export default function useAxios(token) {
	let http: AxiosInstance;

	const setHttp = () => {
		http = createAxiosInstance(token);
	};

	useEffect(() => {
		setHttp();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	setHttp();

	return http;
}
