import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Home } from './pages/Home/index';
import { Clubs } from './pages/Clubs';
import { InactivePlayers, Players } from './pages/Players';
import { Settings } from './pages/Settings/index';
import { Login } from './pages/Login/index';
import { LOCAL_STORAGE_KEY } from './utils/constants';
import { Player } from './components/Players/Player';
import { EditPlayer } from './components/Players/EditPlayer';
import { Club } from './components/Clubs/Club';
import { EditClub } from './components/Clubs/EditClub';
import { CreateClub } from './components/Clubs/CreateClub';
import { CreatePlayer } from './components/Players/CreatePlayer';
import Transfers from './pages/Transfers';
import NotFound from './pages/NotFound';
import { Transfer } from './components/Transfers/Transfer';

export function App() {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		let localData = localStorage.getItem(LOCAL_STORAGE_KEY);
		if (!!localData && localData !== 'null') {
			setIsLoggedIn(true);
			setIsLoading(false);
		} else {
			setIsLoading(false);
		}
	}, []);

	let routes = null;
	if (isLoggedIn) {
		routes = (
			<>
				<Switch>
					<Route path="/" exact component={Home} />

					<Route path="/clubs" exact component={Clubs} />
					<Route path="/clubs/new" exact component={CreateClub} />
					<Route path="/clubs/:clubId" exact component={Club} />
					<Route path="/clubs/:clubId/edit" exact component={EditClub} />

					<Route path="/players" exact component={Players} />
					<Route path="/players/new" exact component={CreatePlayer} />
					<Route path="/players/inactive" exact component={InactivePlayers} />
					<Route path="/players/:registrationNumber" exact component={Player} />
					<Route
						path="/players/:registrationNumber/edit"
						exact
						component={EditPlayer}
					/>

					<Route path="/transfers" exact component={Transfers} />
					<Route path="/transfers/:transferId" exact component={Transfer} />

					<Route path="/settings" exact component={Settings} />

					<Route component={NotFound} />
				</Switch>
			</>
		);
	} else {
		routes = (
			<>
				<Route path="*" exact component={Login} />
			</>
		);
	}

	return <>{isLoading ? <h1>Loading page</h1> : <Switch>{routes}</Switch>}</>;
}
