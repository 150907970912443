import { Card, Page } from '@shopify/polaris';
import InactivePlayersList from '../../components/Players/InactivePlayersList';
import PlayersList from '../../components/Players/PlayersList';
import DefaultLayout from '../../layout/default';

export function Players() {
	return (
		<DefaultLayout>
			<Page
				title="Players"
				primaryAction={{ url: '/players/new', content: 'Add New Player' }}
				secondaryActions={[
					{
						content: 'See Inactive Players',
						accessibilityLabel: 'See Inactive Players',
						url: '/players/inactive',
					},
				]}
				fullWidth
			>
				<Card>
					<PlayersList />
				</Card>
			</Page>
		</DefaultLayout>
	);
}

export function InactivePlayers() {
	return (
		<DefaultLayout>
			<Page
				title="Inactive Players"
				secondaryActions={[
					{
						content: 'See Active Players',
						accessibilityLabel: 'See Active Players',
						url: '/players',
					},
				]}
				fullWidth
			>
				<Card>
					<InactivePlayersList />
				</Card>
			</Page>
		</DefaultLayout>
	);
}
