import { Card, Form, FormLayout, TextField, Toast } from '@shopify/polaris';
import { AxiosInstance } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { LOCAL_STORAGE_KEY } from '../../utils/constants';
import useAxios from '../../utils/hooks/useAxios.hook';
import { AdminDetails } from '../../utils/types';
import { isEmail } from '../../utils/validations';

export function RegistrationDesk({ setIsError }) {
	const [loading, setLoading] = useState(false);
	const [registrationDesk, setRegistrationDesk] = useState<AdminDetails>(null);

	const token = localStorage.getItem(LOCAL_STORAGE_KEY);
	const http: AxiosInstance = useAxios(token);

	const [errorToastActive, setErrorToastActive] = useState(false);
	const [errorToastContent, setErrorToastContent] = useState(
		'Failed to save the changes to the server',
	);
	const toggleToastActive = useCallback(
		() => setErrorToastActive((active) => !active),
		[],
	);

	const [successToastActive, setSuccessToastActive] = useState(false);
	const [successMessage, setSuccessMessage] = useState(
		'Changes saved successfully!',
	);
	const toggleSuccessToastActive = useCallback(
		() => setSuccessToastActive((active) => !active),
		[],
	);

	function isValid() {
		if (!registrationDesk) {
			return;
		}

		if (
			registrationDesk.email.trim() === '' ||
			registrationDesk.password.trim() === ''
		) {
			return false;
		}

		if (!isEmail(registrationDesk.email)) {
			return false;
		}

		return true;
	}

	async function loadRegistrationDeskUser() {
		setLoading(true);
		try {
			const res: any = await http.get('/registration-desk');
			setRegistrationDesk(res.data.registrationDesk);
		} catch (err) {
			setErrorToastContent('Failed to load data from the server');
			setErrorToastActive(true);
			setIsError(true);
			console.error(
				'Failed to load registration desk user details from the server',
			);
		} finally {
			setLoading(false);
		}
	}

	async function updateRegistrationDesk() {
		if (!isValid()) {
			return;
		}
		const payload = { ...registrationDesk };
		setLoading(true);
		try {
			await http.patch('/update-registration-desk', { ...payload });
			setSuccessMessage('Changes saved successfully!');
			setSuccessToastActive(true);
		} catch (error) {
			setErrorToastContent('Something went wrong! Please try again.');
			setErrorToastActive(true);
			console.error(
				'Failed to save the registration desk user details to the server',
				error,
			);
		} finally {
			loadRegistrationDeskUser();
		}
	}

	useEffect(() => {
		loadRegistrationDeskUser();
		// eslint-disable-next-line
	}, []);

	const errorToastMarkup = errorToastActive ? (
		<Toast content={errorToastContent} error onDismiss={toggleToastActive} />
	) : null;

	const successToastMarkup = successToastActive ? (
		<Toast content={successMessage} onDismiss={toggleSuccessToastActive} />
	) : null;

	return (
		<Card
			title="Registration Desk User Settings"
			sectioned
			primaryFooterAction={{
				content: 'Save Changes',
				disabled: loading || !isValid(),
				onAction: updateRegistrationDesk,
			}}
		>
			{successToastMarkup}
			{errorToastMarkup}
			<Form onSubmit={() => {}}>
				<FormLayout>
					<TextField
						label="Email"
						type="email"
						disabled={loading}
						value={
							registrationDesk
								? registrationDesk.email
								: 'registrationdesk@difaksa.com'
						}
						onChange={(email) => {
							setRegistrationDesk({ ...registrationDesk, email });
						}}
						placeholder="Email address used for the Registration Desk user for login"
						clearButton
						onClearButtonClick={() => {
							setRegistrationDesk({ ...registrationDesk, email: '' });
						}}
					/>
					<TextField
						label="Password"
						type="password"
						disabled={loading}
						value={
							registrationDesk
								? registrationDesk.password
								: '*********************'
						}
						onChange={(password) => {
							setRegistrationDesk({ ...registrationDesk, password });
						}}
						placeholder="Enter the password for the Registration Desk user"
						clearButton
						onClearButtonClick={() => {
							setRegistrationDesk({ ...registrationDesk, password: '' });
						}}
					/>
				</FormLayout>
			</Form>
		</Card>
	);
}
