import { Card, TextStyle } from '@shopify/polaris';
import { DocumentsConfig } from '../../utils/types';
import ToggleSwitch from '../Shared/ToggleSwitch';

type IProps = {
	loading: boolean;
	documentsConfig: DocumentsConfig;
	setDocumentsConfig: any;
};

export default function FieldSettingsForm({
	loading,
	documentsConfig,
	setDocumentsConfig,
}: IProps) {
	return (
		<Card.Section title="Required Fields For Registration">
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					gap: 20,
				}}
			>
				<div style={{ width: '50%' }}>
					<Card sectioned>
						<div
							style={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div style={{ display: 'flex', flex: '90' }}>
								<TextStyle variation="strong">Email</TextStyle>
							</div>
							<ToggleSwitch
								disabled={loading}
								isToggled={documentsConfig && documentsConfig.email}
								onToggle={async () => {
									setDocumentsConfig({
										...documentsConfig,
										email: !documentsConfig.email,
									});
								}}
							/>
						</div>
					</Card>
				</div>

				<div style={{ width: '50%' }}>
					<Card sectioned>
						<div
							style={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div style={{ display: 'flex', flex: '90' }}>
								<TextStyle variation="strong">Phone</TextStyle>
							</div>
							<ToggleSwitch
								disabled={loading}
								isToggled={documentsConfig && documentsConfig.phone}
								onToggle={async () => {
									setDocumentsConfig({
										...documentsConfig,
										phone: !documentsConfig.phone,
									});
								}}
							/>
						</div>
					</Card>
				</div>
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					gap: 20,
					marginTop: 20,
				}}
			>
				<div style={{ width: '50%' }}>
					<Card sectioned>
						<div
							style={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div style={{ display: 'flex', flex: '90' }}>
								<TextStyle variation="strong">Home Address (India)</TextStyle>
							</div>
							<ToggleSwitch
								disabled={loading}
								isToggled={documentsConfig && documentsConfig.homeAddress}
								onToggle={async () => {
									setDocumentsConfig({
										...documentsConfig,
										homeAddress: !documentsConfig.homeAddress,
									});
								}}
							/>
						</div>
					</Card>
				</div>

				<div style={{ width: '50%' }}>
					<Card sectioned>
						<div
							style={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div style={{ display: 'flex', flex: '90' }}>
								<TextStyle variation="strong">Saudi Address</TextStyle>
							</div>
							<ToggleSwitch
								disabled={loading}
								isToggled={documentsConfig && documentsConfig.saudiAddress}
								onToggle={async () => {
									setDocumentsConfig({
										...documentsConfig,
										saudiAddress: !documentsConfig.saudiAddress,
									});
								}}
							/>
						</div>
					</Card>
				</div>
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					gap: 20,
					marginTop: 20,
				}}
			>
				<div style={{ width: '50%' }}>
					<Card sectioned>
						<div
							style={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div style={{ display: 'flex', flex: '90' }}>
								<TextStyle variation="strong">Company Name</TextStyle>
							</div>
							<ToggleSwitch
								disabled={loading}
								isToggled={documentsConfig && documentsConfig.companyName}
								onToggle={async () => {
									setDocumentsConfig({
										...documentsConfig,
										companyName: !documentsConfig.companyName,
									});
								}}
							/>
						</div>
					</Card>
				</div>

				<div style={{ width: '50%' }}>
					<Card sectioned>
						<div
							style={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div style={{ display: 'flex', flex: '90' }}>
								<TextStyle variation="strong">Photo</TextStyle>
							</div>
							<ToggleSwitch
								disabled={loading}
								isToggled={documentsConfig && documentsConfig.photo}
								onToggle={async () => {
									setDocumentsConfig({
										...documentsConfig,
										photo: !documentsConfig.photo,
									});
								}}
							/>
						</div>
					</Card>
				</div>
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					gap: 20,
					marginTop: 20,
				}}
			>
				<div style={{ width: '50%' }}>
					<Card sectioned>
						<div
							style={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div style={{ display: 'flex', flex: '90' }}>
								<TextStyle variation="strong">Blood Group</TextStyle>
							</div>
							<ToggleSwitch
								disabled={loading}
								isToggled={documentsConfig && documentsConfig.bloodGroup}
								onToggle={async () => {
									setDocumentsConfig({
										...documentsConfig,
										bloodGroup: !documentsConfig.bloodGroup,
									});
								}}
							/>
						</div>
					</Card>
				</div>

				<div style={{ width: '50%' }}>
					<Card sectioned>
						<div
							style={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div style={{ display: 'flex', flex: '90' }}>
								<TextStyle variation="strong">Date of Birth</TextStyle>
							</div>
							<ToggleSwitch
								disabled={loading}
								isToggled={documentsConfig && documentsConfig.dateOfBirth}
								onToggle={async () => {
									setDocumentsConfig({
										...documentsConfig,
										dateOfBirth: !documentsConfig.dateOfBirth,
									});
								}}
							/>
						</div>
					</Card>
				</div>
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					gap: 20,
					marginTop: 20,
				}}
			>
				<div style={{ width: '50%' }}>
					<Card sectioned>
						<div
							style={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div style={{ display: 'flex', flex: '90' }}>
								<TextStyle variation="strong">Iqama Number</TextStyle>
							</div>
							<ToggleSwitch
								disabled={loading}
								isToggled={documentsConfig && documentsConfig.iqamaNumber}
								onToggle={async () => {
									setDocumentsConfig({
										...documentsConfig,
										iqamaNumber: !documentsConfig.iqamaNumber,
									});
								}}
							/>
						</div>
					</Card>
				</div>

				<div style={{ width: '50%' }}>
					<Card sectioned>
						<div
							style={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div style={{ display: 'flex', flex: '90' }}>
								<TextStyle variation="strong">Passport Number</TextStyle>
							</div>
							<ToggleSwitch
								disabled={loading}
								isToggled={documentsConfig && documentsConfig.passportNumber}
								onToggle={async () => {
									setDocumentsConfig({
										...documentsConfig,
										passportNumber: !documentsConfig.passportNumber,
									});
								}}
							/>
						</div>
					</Card>
				</div>
			</div>
		</Card.Section>
	);
}
