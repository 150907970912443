import {
	Badge,
	Button,
	Card,
	Icon,
	Layout,
	Loading,
	Modal,
	Page,
	ResourceItem,
	ResourceList,
	TextStyle,
	Thumbnail,
	Toast,
} from '@shopify/polaris';
import { DeleteMajor } from '@shopify/polaris-icons';
import { AxiosInstance } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { ArrowRightMinor, ExternalMinor } from '@shopify/polaris-icons';
import { useHistory } from 'react-router-dom';

import { useParams } from 'react-router';
import DefaultLayout from '../../layout/default';
import { ErrorBanner } from '../../utils/components/ErrorBanner';
import {
	LOCAL_STORAGE_KEY,
	SERVER_BASE_URL,
	STATUSES_STYLE,
	TRANSFER_STATUSES,
} from '../../utils/constants';
import useAxios from '../../utils/hooks/useAxios.hook';
import { PlayerFullDetails, TransferListItem } from '../../utils/types';

import logo from '../../assets/images/noLogoTemplate.jpeg';
import avatar from '../../assets/images/avatar.png';

export function Transfer() {
	const { transferId }: any = useParams();
	const history = useHistory();
	const [transfer, setTransfer] = useState<TransferListItem>(null);
	const [player, setPlayer] = useState<PlayerFullDetails>(null);

	const token = localStorage.getItem(LOCAL_STORAGE_KEY);
	const http: AxiosInstance = useAxios(token);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const [toastActive, setToastActive] = useState(false);
	const [isError, setIsError] = useState(false);

	const toggleToastActive = useCallback(
		() => setToastActive((active) => !active),
		[],
	);

	async function handleDeleteTransfer() {
		setIsDeleting(true);

		try {
			await http.delete(`/transfers/${transferId}`);

			history.push('/transfers');
		} catch {
		} finally {
			setIsDeleting(false);
		}
	}

	async function loadPlayerTransfer() {
		try {
			const response: any = await http.get(`/transfers/${transferId}`, {});

			setTransfer(response.data.transfer);
			setPlayer(response.data.player || null);
		} catch (error) {
			setIsError(true);
			setToastActive(true);
			console.error('Failed to load player details from the server');
		}
	}

	const toastMarkup = toastActive ? (
		<Toast
			content="Failed to load player details from the server. Please try again."
			error
			onDismiss={toggleToastActive}
		/>
	) : null;

	useEffect(() => {
		loadPlayerTransfer();
		// eslint-disable-next-line
	}, []);

	return (
		<DefaultLayout>
			<Modal
				open={showDeleteModal}
				onClose={() => setShowDeleteModal(false)}
				title="Are you sure you want to delete this transfer?"
				primaryAction={{
					destructive: true,
					content: 'Confirm Delete',
					onAction: handleDeleteTransfer,
				}}
				secondaryActions={[
					{
						content: 'Cancel',
						onAction: () => setShowDeleteModal(false),
					},
				]}
			>
				<Modal.Section>
					If you discard changes, you’ll delete any edits you made since you
					last saved.
				</Modal.Section>
			</Modal>

			<Page
				title="Transfer Details"
				breadcrumbs={[{ content: 'Player', url: '/transfers' }]}
				primaryAction={
					transfer &&
					transfer.status === 1 && {
						onAction: () => setShowDeleteModal(true),
						content: 'Delete Transfer',
						icon: DeleteMajor,
						destructive: true,
						disabled: isDeleting,
					}
				}
			>
				{toastMarkup}

				{isError ? (
					<ErrorBanner />
				) : transfer === null ? (
					<Loading />
				) : (
					<div style={{ minHeight: '70vh' }}>
						<Card
							sectioned
							title={
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<b>
										{transfer.playerName} ({transfer.playerRegistrationNumber})
									</b>

									<Badge status={STATUSES_STYLE[transfer.status]}>
										{TRANSFER_STATUSES[transfer.status]}
									</Badge>
								</div>
							}
						>
							<Layout>
								<Layout.Section>
									<div style={{ padding: 10 }}>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												height: '100px',
											}}
										>
											<div
												style={{
													flex: 48,
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'center',
												}}
											>
												<Thumbnail
													alt="From club logo"
													source={
														transfer.fromClubLogo
															? SERVER_BASE_URL +
															  '/get-club-logo/?key=' +
															  transfer.fromClubLogo
															: logo
													}
													size="large"
												/>
												<b style={{ paddingTop: 20 }}>
													{transfer.fromClubName}
												</b>
											</div>

											<div style={{ flex: 4 }}>
												<ArrowRightMinor />
											</div>

											<div
												style={{
													flex: 48,
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'center',
												}}
											>
												<Thumbnail
													alt="To club logo"
													source={
														transfer.fromClubLogo
															? SERVER_BASE_URL +
															  '/get-club-logo/?key=' +
															  transfer.toClubLogo
															: logo
													}
													size="large"
												/>
												<b style={{ paddingTop: 20 }}>{transfer.toClubName}</b>
											</div>
										</div>

										<div style={{ paddingTop: 30 }}>
											<Card sectioned>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														justifyContent: 'space-between',
													}}
												>
													<b>Transfer Document</b>

													<Button
														primary
														disabled={transfer.transferDocument === null}
														onClick={() =>
															window.open(
																SERVER_BASE_URL +
																	`/admin/get-player-document?token=${token}&key=${transfer.transferDocument}`,
																'_blank',
															)
														}
													>
														{transfer.transferDocument === null
															? 'No Document Uploaded'
															: 'View'}
													</Button>
												</div>
											</Card>
										</div>
									</div>
								</Layout.Section>
							</Layout>
						</Card>

						{player && (
							<Layout>
								<Layout.Section>
									<div style={{ minHeight: '70vh', marginTop: 20 }}>
										<Card
											title={
												<Layout>
													<Layout.Section>
														<div
															style={{
																display: 'flex',
																flexDirection: 'row',
																alignItems: 'center',
															}}
														>
															<Thumbnail
																alt=""
																source={
																	player.photo
																		? SERVER_BASE_URL +
																		  '/get-player-image/?key=' +
																		  player.photo
																		: avatar
																}
															/>
															<h2
																className="Polaris-Heading"
																style={{ paddingLeft: '15px' }}
															>
																{player.fullName}
															</h2>
															<div
																style={{
																	marginLeft: '15px',
																	display: 'flex',
																}}
															>
																<div style={{ marginLeft: '10px' }}>
																	{player.isActive === true ? (
																		<Badge status="success">Active</Badge>
																	) : (
																		<Badge status="new">Inactive</Badge>
																	)}
																</div>
															</div>
														</div>
													</Layout.Section>
												</Layout>
											}
											sectioned
										>
											<Layout>
												<Layout.Section>
													<div
														style={{
															display: 'flex',
															flexDirection: 'row',
															columnGap: '20px',
															width: '100%',
															height: '100%',
															justifyContent: 'stretch',
														}}
													>
														<div style={{ width: '100%' }}>
															<Card title="DIFA Registration Number" sectioned>
																<TextStyle variation="strong">
																	{player.registrationNumber}
																</TextStyle>
															</Card>
														</div>
													</div>
												</Layout.Section>
											</Layout>
										</Card>

										<Card title="Personal Details" sectioned>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													columnGap: '20px',
													width: '100%',
													height: '100%',
													justifyContent: 'stretch',
												}}
											>
												<div style={{ width: '100%' }}>
													<Card.Section title="Email">
														<TextStyle variation="strong">
															{player.email || '-'}
														</TextStyle>
													</Card.Section>
												</div>
												<div style={{ width: '100%' }}>
													<Card.Section title="Phone">
														<TextStyle variation="strong">
															{player.phone || '-'}
														</TextStyle>
													</Card.Section>
												</div>
												<div style={{ width: '100%' }}>
													<Card.Section title="Date of Birth">
														<TextStyle variation="strong">
															{player.dateOfBirth || '-'}
														</TextStyle>
													</Card.Section>
												</div>
											</div>
											<div
												style={{
													paddingTop: '20px',
													display: 'flex',
													flexDirection: 'row',
													columnGap: '20px',
													width: '100%',
													height: '100%',
													justifyContent: 'stretch',
												}}
											>
												<div style={{ width: '100%' }}>
													<Card.Section title="Blood Group">
														<TextStyle variation="strong">
															{player.bloodGroup || '-'}
														</TextStyle>
													</Card.Section>
												</div>
												<div style={{ width: '100%' }}>
													<Card.Section title="Iqama Number">
														<TextStyle variation="strong">
															{player.iqamaNumber ? player.iqamaNumber : '-'}
														</TextStyle>
													</Card.Section>
												</div>
												<div style={{ width: '100%' }}>
													<Card.Section title="Passport Number">
														<TextStyle variation="strong">
															{player.passportNumber
																? player.passportNumber
																: '-'}
														</TextStyle>
													</Card.Section>
												</div>
											</div>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													columnGap: '20px',
													width: '100%',
													height: '100%',
													justifyContent: 'stretch',
												}}
											>
												<div style={{ width: '100%' }}>
													<Card.Section title="Home Address (India)">
														<TextStyle variation="strong">
															{player.homeAddress || '-'}
														</TextStyle>
													</Card.Section>
												</div>
												<div style={{ width: '100%' }}>
													<Card.Section title="Saudi Address">
														<TextStyle variation="strong">
															{player.saudiAddress || '-'}
														</TextStyle>
													</Card.Section>
												</div>
												<div style={{ width: '100%' }}>
													<Card.Section title="Company Name">
														<TextStyle variation="strong">
															{player.companyName || '-'}
														</TextStyle>
													</Card.Section>
												</div>
											</div>
										</Card>

										<Card sectioned title="Player Documents">
											<ResourceList
												resourceName={{
													singular: 'document',
													plural: 'documents',
												}}
												items={[
													{
														id: 'registrationForm',
														url: player.registrationForm,
														name: 'DIFA Registration Form',
													},
													{
														id: 'affidavit',
														url: player.affidavit,
														name: 'Affidavit',
													},
													{
														id: 'passport',
														url: player.passport,
														name: 'Passport',
													},
													{
														id: 'iqama',
														url: player.iqama,
														name: 'Iqama / Saudi Visa Copy',
													},
												]}
												renderItem={(item) => {
													const { id, url, name } = item;

													return (
														<ResourceItem
															id={id}
															url={
																url
																	? SERVER_BASE_URL +
																	  `/admin/get-player-document?token=${token}&key=${url}`
																	: ''
															}
														>
															<div
																style={{
																	display: 'flex',
																	flexDirection: 'row',
																}}
															>
																<div style={{ flex: '0.95' }}>
																	{url ? (
																		<TextStyle variation="strong">
																			{name}
																		</TextStyle>
																	) : (
																		<TextStyle variation="strong">
																			{name} - No file uploaded
																		</TextStyle>
																	)}
																</div>
																<div style={{ flex: '0.05' }}>
																	<Icon source={ExternalMinor} />
																</div>
															</div>
														</ResourceItem>
													);
												}}
											/>
										</Card>
									</div>
								</Layout.Section>
							</Layout>
						)}
					</div>
				)}
			</Page>
		</DefaultLayout>
	);
}
