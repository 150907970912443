import { Card, Link, Page, TextStyle } from '@shopify/polaris';

export default function NotFound() {
	return (
		<Page>
			<Card sectioned>
				<div
					style={{
						padding: '50px',
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'space-between',
						gap: '40px',
					}}
				>
					<div style={{ fontSize: '60px' }}>
						<TextStyle variation="strong">404</TextStyle>
					</div>
					<div style={{ fontSize: '30px' }}>
						<TextStyle variation="strong">Oops! Page Not Found</TextStyle>
					</div>
					<div style={{ fontSize: '18px' }}>
						<TextStyle variation="strong">
							Return to <Link url="/">Home</Link>
						</TextStyle>
					</div>
				</div>
			</Card>
		</Page>
	);
}
