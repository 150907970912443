import { Card, TextStyle } from '@shopify/polaris';
import { DocumentsConfig } from '../../utils/types';
import ToggleSwitch from '../Shared/ToggleSwitch';

type IProps = {
	loading: boolean;
	documentsConfig: DocumentsConfig;
	setDocumentsConfig: any;
};

export default function DocumentsSettingsForm({
	loading,
	documentsConfig,
	setDocumentsConfig,
}: IProps) {
	return (
		<Card.Section title="Required Documents For Registration">
			<Card sectioned>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ display: 'flex', flex: '90' }}>
						<TextStyle variation="strong">Passport</TextStyle>
					</div>
					<ToggleSwitch
						disabled={loading}
						isToggled={documentsConfig && documentsConfig.passport}
						onToggle={async () => {
							setDocumentsConfig({
								...documentsConfig,
								passport: !documentsConfig.passport,
							});
						}}
					/>
				</div>
			</Card>

			<Card sectioned>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ display: 'flex', flex: '90' }}>
						<TextStyle variation="strong">Iqama / Saudi Visa Copy</TextStyle>
					</div>
					<ToggleSwitch
						disabled={loading}
						isToggled={documentsConfig && documentsConfig.iqama}
						onToggle={async () => {
							setDocumentsConfig({
								...documentsConfig,
								iqama: !documentsConfig.iqama,
							});
						}}
					/>
				</div>
			</Card>

			<Card sectioned>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ display: 'flex', flex: '90' }}>
						<TextStyle variation="strong">DIFA Registration Form</TextStyle>
					</div>
					<ToggleSwitch
						disabled={loading}
						isToggled={documentsConfig && documentsConfig.registrationForm}
						onToggle={async () => {
							setDocumentsConfig({
								...documentsConfig,
								registrationForm: !documentsConfig.registrationForm,
							});
						}}
					/>
				</div>
			</Card>

			<Card sectioned>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ display: 'flex', flex: '90' }}>
						<TextStyle variation="strong">Player Affidavit</TextStyle>
					</div>
					<ToggleSwitch
						disabled={loading}
						isToggled={documentsConfig && documentsConfig.affidavit}
						onToggle={async () => {
							setDocumentsConfig({
								...documentsConfig,
								affidavit: !documentsConfig.affidavit,
							});
						}}
					/>
				</div>
			</Card>
		</Card.Section>
	);
}
