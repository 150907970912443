import {
	Avatar,
	Card,
	EmptyState,
	Heading,
	Layout,
	Loading,
	Page,
	ResourceItem,
	ResourceList,
	Tabs,
	TextStyle,
	Thumbnail,
	Toast,
} from '@shopify/polaris';
import { AxiosInstance } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import DefaultLayout from '../../layout/default';
import { ErrorBanner } from '../../utils/components/ErrorBanner';
import { LOCAL_STORAGE_KEY, SERVER_BASE_URL } from '../../utils/constants';
import useAxios from '../../utils/hooks/useAxios.hook';
import {
	ClubFullDetails,
	ClubURLParams,
	GetClubResponse,
	GetPlayersByClubId,
	PlayerListItem,
} from '../../utils/types';
import noLogoTemplate from '../../assets/images/noLogoTemplate.jpeg';
import avatar from '../../assets/images/avatar.png';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

export function Club() {
	const { clubId } = useParams<ClubURLParams>();
	const [club, setClub] = useState<ClubFullDetails>(null);
	const [players, setPlayers] = useState([]);

	const token = localStorage.getItem(LOCAL_STORAGE_KEY);
	const http: AxiosInstance = useAxios(token);

	const [selectedTab, setSelectedTab] = useState(0);
	const [loading, setLoading] = useState(false);
	const [toastActive, setToastActive] = useState(false);
	const [isError, setIsError] = useState(false);
	const [exporting, setExporting] = useState(false);

	const toggleToastActive = useCallback(
		() => setToastActive((active) => !active),
		[],
	);

	async function loadClub() {
		setIsError(false);
		try {
			const response: GetClubResponse = await http.get('/club', {
				params: {
					clubId,
				},
			});

			setClub({ ...response.data.club });
			await loadPlayers();
			setIsError(false);
		} catch (error) {
			setIsError(true);
			setToastActive(true);
			console.error('Failed to load club details from the server');
		}
	}

	async function loadPlayers() {
		if (!clubId) return;

		setIsError(false);
		setLoading(true);

		const statusFilter = tabs[selectedTab].panelID;

		try {
			const response: GetPlayersByClubId = await http.get(
				`/club/${clubId}/players`,
				{
					params: {
						statusFilter,
					},
				},
			);
			const allPlayers = response.data.players;
			setPlayers([...allPlayers]);
			setIsError(false);
		} catch (error) {
			setToastActive(true);
			console.error('Failed to load players from the server', error);
			setIsError(true);
		}

		setLoading(false);
	}

	async function handleExport() {
		setExporting(true);
		try {
			const response: any = await http.get('/export-players', {
				params: { clubId },
			});
			const players = response.data.players;

			const workbook = new Workbook();
			const worksheet = workbook.addWorksheet(`Players-${clubId}`);

			worksheet.columns = [
				{ header: 'Player ID', key: 'playerId', width: 10 },
				{ header: 'Club ID', key: 'clubId', width: 10 },
				{ header: 'Reg Number', key: 'registrationNumber', width: 10 },
				{ header: 'Full Name', key: 'fullName', width: 32 },
				{ header: 'Email', key: 'email', width: 32 },
				{ header: 'Mobile Number', key: 'phone', width: 32 },
				{ header: 'Date of Birth', key: 'dateOfBirth', width: 15 },
				{ header: 'Blood Group', key: 'bloodGroup', width: 15 },
				{ header: 'Home Address (India)', key: 'homeAddress', width: 40 },
				{ header: 'Saudi Address', key: 'homeAddress', width: 40 },
				{ header: 'Company Name', key: 'companyName', width: 12 },
				{ header: 'Passport Number', key: 'passportNumber', width: 12 },
				{ header: 'Iqama Number', key: 'iqamaNumber', width: 12 },
				{ header: 'Is Active', key: 'isActive', width: 12 },
			];

			worksheet.addRows(players.rows);

			const buf = await workbook.xlsx.writeBuffer();

			saveAs(new Blob([buf]), `Players-${clubId}.xlsx`);
		} catch (err) {
			setToastActive(true);
			console.error('Failed to load all players from the server', err);
			setIsError(true);
		} finally {
			setExporting(false);
		}
	}

	const toastMarkup = toastActive ? (
		<Toast
			content="Failed to load club details from the server. Please try again."
			error
			onDismiss={toggleToastActive}
		/>
	) : null;

	const emptyStateMarkup = isError ? (
		<div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
			<ErrorBanner />
		</div>
	) : !players.length ? (
		<EmptyState
			heading="No players to show"
			image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
		>
			<p>Players in this club will be shown here</p>
		</EmptyState>
	) : undefined;

	const renderItem = (item: PlayerListItem) => {
		const {
			playerId,
			registrationNumber,
			fullName,
			email,
			iqamaNumber,
			photo,
		} = item;

		const media = (
			<Avatar
				source={
					photo ? SERVER_BASE_URL + '/get-player-image/?key=' + photo : avatar
				}
				size="medium"
				name={fullName}
			/>
		);

		return (
			<ResourceItem
				url={`/players/${registrationNumber}`}
				id={playerId}
				media={media}
			>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ flex: '1', verticalAlign: 'bottom' }}>
						<TextStyle variation="strong">{registrationNumber}</TextStyle>
					</div>
					<div style={{ flex: '1' }}>{fullName}</div>
					<div style={{ flex: '1' }}>{email || '-'}</div>
					<div style={{ flex: '1' }}>{iqamaNumber || '-'}</div>
				</div>
			</ResourceItem>
		);
	};

	useEffect(() => {
		loadClub();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		loadPlayers();
		// eslint-disable-next-line
	}, [selectedTab]);

	const handleTabChange = useCallback(
		(selectedTabIndex) => setSelectedTab(selectedTabIndex),
		[],
	);
	const tabs = [
		{
			id: 'all',
			content: 'All',
			accessibilityLabel: 'All players',
			panelID: '4',
		},
		{
			id: 'approved',
			content: 'Approved',
			panelID: '3',
		},
		{
			id: 'verified',
			content: 'Verified',
			panelID: '2',
		},
		{
			id: 'unattended',
			content: 'Pending',
			panelID: '0',
		},
		{
			id: 'onHold',
			content: 'On Hold',
			panelID: '1',
		},
	];

	return (
		<DefaultLayout>
			<Page
				primaryAction={{
					content: 'Edit Club',
					disabled: isError || !club,
					url: `/clubs/${clubId}/edit`,
				}}
				title="Club Details"
				breadcrumbs={[{ content: 'Club', url: '/clubs' }]}
			>
				{toastMarkup}
				{isError ? (
					<ErrorBanner />
				) : club === null ? (
					<Loading />
				) : (
					<div style={{ minHeight: '70vh' }}>
						<Card
							title={
								<Layout>
									<Layout.Section>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
											}}
										>
											<Thumbnail
												size="large"
												source={
													club.logo
														? SERVER_BASE_URL +
														  '/get-club-logo/?key=' +
														  club.logo
														: noLogoTemplate
												}
												alt="Logo"
											/>
											<h2
												className="Polaris-Heading"
												style={{ paddingLeft: '15px' }}
											>
												{club.name}
												{club.code !== null && ' | ' + club.code}
											</h2>
										</div>
									</Layout.Section>
								</Layout>
							}
							sectioned
						>
							<Layout>
								<Layout.Section>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											columnGap: '20px',
											width: '100%',
											height: '100%',
											justifyContent: 'stretch',
										}}
									>
										<div style={{ width: '100%' }}>
											<Card title="Email" sectioned>
												<TextStyle variation="strong">{club.email}</TextStyle>
											</Card>
										</div>
										<div style={{ width: '100%' }}>
											<Card title="Phone" sectioned>
												<TextStyle variation="strong">
													{club.phone || '-'}
												</TextStyle>
											</Card>
										</div>
									</div>
								</Layout.Section>
							</Layout>
						</Card>
						<Card sectioned>
							<div
								style={{
									padding: '20px 20px',
									borderBottom: '0.2rem solid #e1e3e5',
								}}
							>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									<div style={{ flex: '1' }}>
										<TextStyle variation="strong">Chairman</TextStyle>
									</div>
									<div style={{ flex: '1' }}>
										<TextStyle variation="strong">President</TextStyle>
									</div>
									<div style={{ flex: '1' }}>
										<TextStyle variation="strong">General Secretary</TextStyle>
									</div>
									<div style={{ flex: '1' }}>
										<TextStyle variation="strong">Treasurer</TextStyle>
									</div>
									<div style={{ flex: '1' }}>
										<TextStyle variation="strong">Team Manager</TextStyle>
									</div>
								</div>
							</div>
							<ResourceList
								resourceName={{ singular: 'member', plural: 'members' }}
								items={[
									{
										chairman: club.chairman,
										president: club.president,
										generalSecretary: club.generalSecretary,
										treasurer: club.treasurer,
										teamManager: club.teamManager,
									},
								]}
								renderItem={(item) => {
									const {
										chairman,
										president,
										generalSecretary,
										treasurer,
										teamManager,
									} = item;

									return (
										<ResourceItem id="1" url={''}>
											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div style={{ flex: '1' }}>
													<TextStyle variation="strong">
														{chairman || '-'}
													</TextStyle>
												</div>
												<div style={{ flex: '1' }}>
													<TextStyle variation="strong">
														{president || '-'}
													</TextStyle>
												</div>
												<div style={{ flex: '1' }}>
													<TextStyle variation="strong">
														{generalSecretary || '-'}
													</TextStyle>
												</div>
												<div style={{ flex: '1' }}>
													<TextStyle variation="strong">
														{treasurer || '-'}
													</TextStyle>
												</div>
												<div style={{ flex: '1' }}>
													<TextStyle variation="strong">
														{teamManager || '-'}
													</TextStyle>
												</div>
											</div>
										</ResourceItem>
									);
								}}
							/>
						</Card>
						<Card>
							<div
								style={{
									paddingTop: 20,
									paddingLeft: 20,
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<button
									id="exportButton"
									disabled={loading || isError || exporting}
									className={
										loading || isError || exporting
											? 'Polaris-Button Polaris-Button--primary Polaris-Button--disabled'
											: 'Polaris-Button Polaris-Button--primary'
									}
									onClick={handleExport}
								>
									{exporting ? 'Exporting...' : 'Export to Excel'}
								</button>
							</div>
							<div
								style={loading || isError ? { pointerEvents: 'none' } : null}
							>
								<Tabs
									tabs={tabs}
									selected={selectedTab}
									onSelect={handleTabChange}
								/>
							</div>
							<div style={{ padding: '20px' }}>
								<div style={{ paddingBottom: '20px' }}>
									<Heading>
										{tabs[selectedTab].panelID === '1'
											? `Players ${tabs[selectedTab].content} ${
													players && ' (' + players.length + ')'
											  }`
											: `${tabs[selectedTab].content} Players ${
													players && ' (' + players.length + ')'
											  }`}
									</Heading>
								</div>
								<div
									style={{
										padding: '10px 10px',
										borderBottom: '0.2rem solid #e1e3e5',
									}}
								>
									<div style={{ display: 'flex', flexDirection: 'row' }}>
										<div style={{ flex: '0.25' }}>
											<TextStyle variation="strong"> </TextStyle>
										</div>
										<div style={{ flex: '1' }}>
											<TextStyle variation="strong">Register Number</TextStyle>
										</div>
										<div style={{ flex: '1' }}>
											<TextStyle variation="strong">Full Name</TextStyle>
										</div>
										<div style={{ flex: '1' }}>
											<TextStyle variation="strong">Email</TextStyle>
										</div>
										<div style={{ flex: '1' }}>
											<TextStyle variation="strong">Iqama Number</TextStyle>
										</div>
									</div>
								</div>
								<ResourceList
									emptyState={emptyStateMarkup}
									loading={loading}
									resourceName={{ singular: 'player', plural: 'players' }}
									items={players}
									renderItem={renderItem}
								/>
								{toastMarkup}
							</div>
						</Card>
					</div>
				)}
			</Page>
		</DefaultLayout>
	);
}
