import {
	Button,
	Card,
	FormLayout,
	Modal,
	Page,
	TextField,
	Toast,
} from '@shopify/polaris';
import { LockMajor } from '@shopify/polaris-icons';
import { AxiosInstance } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import DefaultLayout from '../../layout/default';
import { LOCAL_STORAGE_KEY } from '../../utils/constants';
import useAuthContext from '../../utils/hooks/useAuthContext.hook';
import useAxios from '../../utils/hooks/useAxios.hook';
import { AdminDetails } from '../../utils/types';

export function Settings() {
	const { user } = useAuthContext();
	const [admin, setAdmin] = useState<AdminDetails>(null);

	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [loading, setLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [unlocked, setUnlocked] = useState(false);
	const [lockPassword, setLockPassword] = useState('');

	const [modalActive, setModalActive] = useState(false);

	const [errorToastActive, setErrorToastActive] = useState(false);
	const [errorMessage, setErrorMessage] = useState(
		'Something went wrong! Please try again.',
	);
	const toggleActive = useCallback(
		() => setErrorToastActive((toastActive) => !toastActive),
		[],
	);

	const [successToastActive, setSuccessToastActive] = useState(false);
	const toggleSuccessToastActive = useCallback(
		() => setSuccessToastActive((active) => !active),
		[],
	);

	const token = localStorage.getItem(LOCAL_STORAGE_KEY);
	const http: AxiosInstance = useAxios(token);

	async function loadAdmin() {
		setLoading(true);
		try {
			const response: any = await http.get(`/`, {
				params: {
					email: user.email,
				},
			});

			setAdmin(response.data.admin);
		} catch (error) {
			const status = error.response?.status;

			if (status) {
				setErrorMessage(error.response.data.message);
			} else {
				setErrorMessage('Something went wrong! Please try again.');
			}

			setIsError(true);
			toggleActive();
		} finally {
			setLoading(false);
		}
	}

	async function unlock() {
		setLoading(true);
		try {
			await http.post(`/login`, {
				email: user.email,
				password: lockPassword,
			});
			setUnlocked(true);
		} catch (error) {
			const status = error.response?.status;

			if (status) {
				setErrorMessage(error.response.data.message);
			} else {
				setErrorMessage('Something went wrong! Please try again.');
			}

			toggleActive();
		} finally {
			setLoading(false);
			setModalActive(false);
		}
	}

	async function updatePassword() {
		setLoading(true);
		try {
			await http.patch(`/update-admin-password`, {
				email: admin.email,
				password: newPassword,
			});
			loadAdmin();
			setUnlocked(false);
			setSuccessToastActive(true);
		} catch (error) {
			const status = error.response?.status;

			if (status) {
				setErrorMessage(error.response.data.message);
			} else {
				setErrorMessage('Something went wrong! Please try again.');
			}

			toggleActive();
		} finally {
			setLoading(false);
			setModalActive(false);
		}
	}

	function validateInputs() {
		if (newPassword === '') {
			return false;
		}

		if (confirmPassword === '') {
			return false;
		}

		if (newPassword !== confirmPassword) {
			return false;
		}

		return true;
	}

	useEffect(() => {
		loadAdmin();
		// eslint-disable-next-line
	}, []);

	const errorToast = errorToastActive ? (
		<Toast content={errorMessage} error onDismiss={toggleActive} />
	) : null;

	const successToastMarkup = successToastActive ? (
		<Toast
			content="Password changes successfully!"
			onDismiss={toggleSuccessToastActive}
		/>
	) : null;

	const saveButton = (
		<Button
			primary
			disabled={loading || isError || !validateInputs()}
			onClick={updatePassword}
		>
			Save Changes
		</Button>
	);
	const unlockButton = (
		<Button
			outline
			destructive
			icon={LockMajor}
			onClick={() => {
				setModalActive(true);
			}}
		>
			Change
		</Button>
	);

	return (
		<DefaultLayout>
			<Page title="Admin Settings" narrowWidth>
				{errorToast}
				{successToastMarkup}
				<div style={{ minHeight: '70vh' }}>
					<Card sectioned>
						<FormLayout>
							<TextField
								label="Admin Email"
								value={admin?.email || user?.email || ''}
								onChange={() => {}}
								disabled
							/>
						</FormLayout>
					</Card>
					<Card title="Change Password" sectioned>
						<FormLayout>
							<TextField
								label="New Password"
								type="password"
								value={newPassword}
								disabled={!unlocked}
								onChange={(value) => {
									setNewPassword(value);
								}}
							/>
							<TextField
								label="Confirm New Password"
								type="password"
								value={confirmPassword}
								error={newPassword !== confirmPassword}
								disabled={!unlocked}
								onChange={(value) => {
									setConfirmPassword(value);
								}}
							/>
							{unlocked ? saveButton : unlockButton}
						</FormLayout>
						<Modal
							open={modalActive}
							onClose={() => {
								setModalActive(false);
							}}
							title="Enter Admin Pasword"
							primaryAction={{
								content: 'Unlock',
								disabled: loading || isError,
								onAction: unlock,
							}}
						>
							<Modal.Section>
								<FormLayout>
									<TextField
										label="Please enter your current password to unlock these settings"
										value={lockPassword}
										type="password"
										onChange={(value) => {
											setLockPassword(value);
										}}
									/>
								</FormLayout>
							</Modal.Section>
						</Modal>
					</Card>
				</div>
			</Page>
		</DefaultLayout>
	);
}
