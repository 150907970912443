import {
	Card,
	EmptyState,
	Pagination,
	ResourceItem,
	ResourceList,
	TextField,
	TextStyle,
	Thumbnail,
	Toast,
} from '@shopify/polaris';
import { AxiosInstance } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { ErrorBanner } from '../../utils/components/ErrorBanner';
import {
	CLUBS_LIMIT,
	LOCAL_STORAGE_KEY,
	SERVER_BASE_URL,
} from '../../utils/constants';
import useAxios from '../../utils/hooks/useAxios.hook';
import { ClubListItem, GetAllClubsResponse } from '../../utils/types';
import noLogoTemplate from '../../assets/images/noLogoTemplate.jpeg';

export default function ClubsList() {
	const [clubs, setClubs] = useState([]);
	const [count, setCount] = useState(0);

	const [searchText, setSearchText] = useState('');

	const [loading, setLoading] = useState(false);
	const [toastActive, setToastActive] = useState(false);
	const [isError, setIsError] = useState(false);
	const [offset, setOffset] = useState(0);

	const toggleToastActive = useCallback(
		() => setToastActive((active) => !active),
		[],
	);

	const token = localStorage.getItem(LOCAL_STORAGE_KEY);
	const http: AxiosInstance = useAxios(token);

	async function getClubs() {
		setIsError(false);
		setLoading(true);
		try {
			const response: GetAllClubsResponse = await http.get('/all-clubs', {
				params: {
					limit: CLUBS_LIMIT,
					offset,
					searchText,
				},
			});
			const allClubs = response.data.clubs?.rows;
			setClubs([...allClubs]);
			setCount(response.data.clubs.count);
			setIsError(false);
		} catch (error) {
			setToastActive(true);
			console.error('Failed to load all clubs from the server');
			setIsError(true);
		}

		setLoading(false);
	}

	const toastMarkup = toastActive ? (
		<Toast
			content="Failed to load all clubs from the server. Please try again."
			error
			onDismiss={toggleToastActive}
		/>
	) : null;

	const emptyStateMarkup = isError ? (
		<div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
			<ErrorBanner />
		</div>
	) : !clubs.length ? (
		<EmptyState
			heading="No clubs to show"
			image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
		>
			<p>
				{searchText === ''
					? 'All registered clubs will be shown here'
					: 'No items matching your search.'}
			</p>
		</EmptyState>
	) : undefined;

	useEffect(() => {
		getClubs();
		// eslint-disable-next-line
	}, [offset]);

	function handleEnterKeyPress(event) {
		const enterKeyPressed = event.keyCode === 13;
		if (enterKeyPressed) {
			event.preventDefault();
			setOffset(0);
			getClubs();
		}
	}

	const renderItem = (item: ClubListItem) => {
		const { clubId, name, code, logo, email, phone } = item;
		const media = (
			<Thumbnail
				source={
					logo
						? SERVER_BASE_URL + '/get-club-logo/?key=' + logo
						: noLogoTemplate
				}
				alt="Logo"
			/>
		);

		return (
			<ResourceItem url={`/clubs/${clubId}`} id={clubId} media={media}>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ flex: '1' }}>
						<TextStyle variation="strong">{name}</TextStyle>
					</div>
					<div style={{ flex: '1' }}>{code}</div>
					<div style={{ flex: '1' }}>{email}</div>
					<div style={{ flex: '1' }}>{phone || '-'}</div>
				</div>
			</ResourceItem>
		);
	};

	return (
		<Card sectioned>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<div style={{ flex: '0.8', verticalAlign: 'bottom' }}>
					<div onKeyDown={handleEnterKeyPress}>
						<TextField
							label=""
							value={searchText}
							onChange={(value) => {
								setSearchText(value);
							}}
							placeholder="Search using Club Name or Email"
							clearButton
							onClearButtonClick={() => {
								setSearchText('');
							}}
							disabled={isError || loading}
						/>
					</div>
				</div>
				<div
					style={{
						flex: '0.2',
						paddingLeft: '10px',
						verticalAlign: 'center',
					}}
				>
					<button
						id="searchButton"
						disabled={loading || isError}
						className={
							loading || isError
								? 'Polaris-Button Polaris-Button--disabled'
								: 'Polaris-Button'
						}
						style={{ width: '100%' }}
						onClick={() => {
							setOffset(0);
							getClubs();
						}}
					>
						Apply Search
					</button>
				</div>
			</div>
			<div
				style={{
					padding: '20px 20px',
					borderBottom: '0.2rem solid #e1e3e5',
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ flex: '0.33' }}>
						<TextStyle variation="strong"> </TextStyle>
					</div>
					<div style={{ flex: '1' }}>
						<TextStyle variation="strong">Club Name</TextStyle>
					</div>
					<div style={{ flex: '1' }}>
						<TextStyle variation="strong">Club Code</TextStyle>
					</div>
					<div style={{ flex: '1' }}>
						<TextStyle variation="strong">Email</TextStyle>
					</div>
					<div style={{ flex: '1' }}>
						<TextStyle variation="strong">Phone</TextStyle>
					</div>
				</div>
			</div>
			<ResourceList
				emptyState={emptyStateMarkup}
				loading={loading}
				resourceName={{ singular: 'club', plural: 'clubs' }}
				items={clubs}
				renderItem={renderItem}
			/>
			{toastMarkup}
			<Pagination
				hasPrevious={!(offset === 0) && !loading}
				onPrevious={() => {
					setOffset(offset - CLUBS_LIMIT);
				}}
				hasNext={offset + clubs.length < count && !loading}
				onNext={() => {
					setOffset(offset + CLUBS_LIMIT);
				}}
			/>
			{loading || !clubs.length ? (
				''
			) : (
				<div style={{ marginTop: '10px' }}>
					<TextStyle variation="strong">
						Showing {offset + 1} -{' '}
						{offset + CLUBS_LIMIT > count ? count : offset + CLUBS_LIMIT} of{' '}
						{count}
					</TextStyle>
				</div>
			)}
		</Card>
	);
}
