import { useCallback, useEffect, useState } from 'react';
import { AuthContext } from '../hooks/useAuthContext.hook';
import useAxios from '../hooks/useAxios.hook';
import { AxiosInstance } from 'axios';

export interface AuthContextType {
	token: string;
	http: AxiosInstance;
	user: any;
	loaded: boolean;
	authenticated: boolean;
}

const LOCAL_STORAGE_KEY = 'difa-app-token';

const AuthContextWrapper = ({ children }) => {
	const token = localStorage.getItem(LOCAL_STORAGE_KEY);
	const http: AxiosInstance = useAxios(token);
	const [loaded, setLoaded] = useState(false);
	const [authenticated, setAuthenticated] = useState(false);
	const [user, setUser] = useState(null);

	const setUserHandler = useCallback(() => {
		if (!token) return;

		http
			.get('/me')
			.then((res: any) => {
				if (res.data) setUser(res.data.admin);

				const isAuthenticated = res.status === 200;
				setAuthenticated(isAuthenticated);
				setLoaded(true);
			})
			.catch((error) => {
				setAuthenticated(false);
				setLoaded(true);
				console.error('Could not set user', error);
				localStorage.setItem(LOCAL_STORAGE_KEY, '');
				// TODO: add toast.showError();
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setUserHandler();
	}, [token, setUserHandler]);

	const sharedState: AuthContextType = {
		token,
		http,
		user,
		loaded,
		authenticated,
	};

	return (
		<AuthContext.Provider value={sharedState}>{children}</AuthContext.Provider>
	);
};

export default AuthContextWrapper;
