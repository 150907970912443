import MainNav from '../components/layout/NavMain';
import TopBarMain from '../components/layout/TopBarMain';
import { Frame } from '@shopify/polaris';
import { useCallback, useState } from 'react';

export default function DefaultLayout({ children }) {
	const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

	const toggleMobileNavigationActive = useCallback(
		() =>
			setMobileNavigationActive(
				(mobileNavigationActive) => !mobileNavigationActive,
			),
		[],
	);

	return (
		<div>
			<Frame
				topBar={
					<TopBarMain onNavigationToggle={toggleMobileNavigationActive} />
				}
				globalRibbon={
					<div
						style={{
							background: '#FFFFFF',
							color: 'black',
							padding: '10px',
							textAlign: 'left',
							borderTop: '1px solid #DDDDDD',
							display: 'flex',
							flexDirection: 'row',
							gap: 8,
						}}
					>
						© {new Date().getFullYear()} DIFA KSA |
						<b>
							<a
								href="https://teamartizen.com"
								style={{ color: 'black', textDecoration: 'none' }}
								target="_blank"
								rel="noopener noreferrer"
							>
								Artizen
							</a>
						</b>
						<b style={{ marginLeft: 'auto', color: 'grey' }}>v23.11.2024</b>
					</div>
				}
				navigation={<MainNav />}
				showMobileNavigation={mobileNavigationActive}
				onNavigationDismiss={toggleMobileNavigationActive}
			>
				{children}
			</Frame>
		</div>
	);
}
