import { Card, Page } from '@shopify/polaris';
import ClubsList from '../../components/Clubs/ClubsList';
import DefaultLayout from '../../layout/default';

export function Clubs() {
	return (
		<DefaultLayout>
			<Page
				title="Clubs"
				fullWidth
				primaryAction={{ url: '/clubs/new', content: 'Add New Club' }}
			>
				<Card>
					<ClubsList />
				</Card>
			</Page>
		</DefaultLayout>
	);
}
