import ReactDOM from 'react-dom';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';

import '@shopify/polaris/dist/styles.css';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import AuthContextWrapper from './utils/components/AuthContextWrapper';
import LinkComponent from './components/layout/LinkComponent';
import Logo from './assets/images/logo.png';

const theme = {
	logo: {
		width: 220,
		topBarSource: Logo,
		contextualSaveBarSource: Logo,
		url: '/',
		accessibilityLabel: 'Dammam India Football Association',
	},
	colors: {
		surface: '#111213',
		onSurface: '#111213',
		interactive: '#2e72d2',
		secondary: '#111213',
		primary: '#1890ff',
		critical: '#d82c0d',
		warning: '#ffc453',
		highlight: '#5bcdda',
		success: '#52c41a',
		decorative: '#ffc96b',
	},
};

ReactDOM.render(
	<AppProvider
		i18n={enTranslations}
		linkComponent={LinkComponent}
		theme={theme}
	>
		<BrowserRouter>
			<AuthContextWrapper>
				<App />
			</AuthContextWrapper>
		</BrowserRouter>
	</AppProvider>,
	document.getElementById('root'),
);
