import {
	Card,
	FormLayout,
	Frame,
	Layout,
	Page,
	TextField,
	Toast,
} from '@shopify/polaris';
import { useCallback, useState } from 'react';
import { AxiosInstance } from 'axios';
import useAxios from '../../utils/hooks/useAxios.hook';
import loginBG from '../../assets/images/loginBG.jpg';

const LOCAL_STORAGE_KEY = 'difa-app-token';

export function Login() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [toastActive, setToastActive] = useState(false);
	const [errorMessage, setErrorMessage] = useState(
		'Something went wrong! Please try again.',
	);

	const token = localStorage.getItem(LOCAL_STORAGE_KEY);
	const http: AxiosInstance = useAxios(token);

	const handleKeyPress = (event) => {
		const enterKeyPressed = event.keyCode === 13;
		if (enterKeyPressed) {
			login();
		}
	};

	const toggleActive = useCallback(
		() => setToastActive((toastActive) => !toastActive),
		[],
	);

	const validateInputs = () => {
		if (email.length === 0 || password.length === 0) {
			setErrorMessage('Please enter a valid email address and password!');
			toggleActive();
			return false;
		}
		return true;
	};

	const login = async () => {
		if (!validateInputs()) {
			return;
		}
		setLoading(true);
		try {
			const response = await http.post(`/login`, {
				email,
				password,
			});
			const token = String(response.data);
			localStorage.setItem(LOCAL_STORAGE_KEY, token);
			setLoading(false);
			window.location.pathname = '/';
		} catch (error) {
			const status = error.response?.status;

			if (status) {
				setErrorMessage(error.response.data.message);
			} else {
				setErrorMessage('Something went wrong! Please try again.');
			}

			toggleActive();
			setLoading(false);
		}
	};

	const errorToast = toastActive ? (
		<Toast content={errorMessage} error onDismiss={toggleActive} />
	) : null;

	return (
		<Frame>
			<div
				style={{
					position: 'absolute',
					left: '0',
					right: '0',
					top: '0',
					bottom: '0',
					margin: 'auto',
					maxWidth: '100%',
					color: 'white',
					maxHeight: '100%',
					overflow: 'auto',
					backgroundColor: 'black',
					background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${loginBG})`,
					backgroundSize: '100%',
				}}
			>
				<div
					style={{
						color: 'white',
						width: '600px',
						height: '400px',
						position: 'absolute',
						left: '0',
						right: '0',
						top: '0',
						bottom: '0',
						margin: 'auto',
						maxWidth: '100%',
						maxHeight: '100%',
						overflow: 'auto',
					}}
				>
					<Page title="DIFA Admin Panel" narrowWidth>
						<div style={{ color: 'black' }}>
							<Layout sectioned>
								<Card
									title="Log In"
									sectioned
									primaryFooterAction={{
										content: 'Login',
										loading,
										onAction: login,
									}}
								>
									<FormLayout>
										<TextField
											label="Email"
											onChange={(value) => {
												setEmail(value);
											}}
											value={email}
											autoComplete="email"
											type="email"
											disabled={loading}
										/>
										<div onKeyDown={handleKeyPress}>
											<TextField
												type="password"
												label="Password"
												onChange={(value) => {
													setPassword(value);
												}}
												value={password}
												autoComplete="email"
												disabled={loading}
											/>
										</div>
									</FormLayout>
								</Card>
								{errorToast}
							</Layout>
						</div>
					</Page>
				</div>
			</div>
		</Frame>
	);
}
