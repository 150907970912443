import {
	Banner,
	Button,
	Card,
	FormLayout,
	Loading,
	Page,
	Select,
	Spinner,
	TextField,
	TextStyle,
	Thumbnail,
	Toast,
} from '@shopify/polaris';
import { AxiosInstance } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import DefaultLayout from '../../layout/default';
import { ErrorBanner } from '../../utils/components/ErrorBanner';
import {
	LOCAL_STORAGE_KEY,
	MAX_DOCUMENT_SIZE,
	MAX_IMAGE_SIZE,
	SERVER_BASE_URL,
	SUPPORTED_DOCUMENT_MIMETYPES,
	SUPPORTED_IMAGE_MIMETYPES,
} from '../../utils/constants';
import { isEmail } from '../../utils/validations';
import useAxios from '../../utils/hooks/useAxios.hook';
import { Redirect } from 'react-router-dom';
import {
	GetClubsNameListResponse,
	UploadFileResponse,
} from '../../utils/types';
import { v4 } from 'uuid';
import avatar from '../../assets/images/avatar.png';

export function CreatePlayer() {
	const [player, setPlayer] = useState({
		fullName: '',
		email: '',
		phone: '',
		dateOfBirth: '',
		bloodGroup: '',
		homeAddress: '',
		saudiAddress: '',
		companyName: '',
		iqamaNumber: '',
		passportNumber: '',
		photo: '',
		iqama: '',
		registrationForm: '',
		passport: '',
		affidavit: '',
		password: '',
		status: 'unattended',
	});
	const [s3Id, setS3Id] = useState<string>();

	const [playerStatus, setPlayerStatus] = useState('unattended');
	const statusOptions = [
		{ label: 'Pending', value: 'unattended' },
		{ label: 'On Hold', value: 'onHold' },
		{ label: 'Approved', value: 'approved' },
		{ label: 'Verified', value: 'fullyApproved' },
	];

	const bloodGroups = [
		{ label: 'None', value: '-' },
		{ label: 'A+', value: 'A+' },
		{ label: 'A-', value: 'A-' },
		{ label: 'B+', value: 'B+' },
		{ label: 'B-', value: 'B-' },
		{ label: 'AB+', value: 'AB+' },
		{ label: 'AB-', value: 'AB-' },
		{ label: 'O+', value: 'O+' },
		{ label: 'O-', value: 'O-' },
	];

	const [playerClub, setPlayerClub] = useState(null);
	const [clubs, setClubs] = useState([]);

	const photoRef = useRef(null);
	const registrationFormRef = useRef(null);
	const affidavitRef = useRef(null);
	const passportRef = useRef(null);
	const iqamaRef = useRef(null);

	const token = localStorage.getItem(LOCAL_STORAGE_KEY);
	const http: AxiosInstance = useAxios(token);

	const [toastContent, setToastContent] = useState(
		'Failed to save the player details to the server',
	);
	const [toastActive, setToastActive] = useState(false);

	const [successToastActive, setSuccessToastActive] = useState(false);
	const [successToastContent, setSuccessToastContent] = useState(
		'Changes saved successfully!',
	);

	const [isError, setIsError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [uploading, setUploading] = useState(false);

	const toggleToastActive = useCallback(
		() => setToastActive((active) => !active),
		[],
	);

	const toggleSuccessToastActive = useCallback(
		() => setSuccessToastActive((active) => !active),
		[],
	);

	const handleStatusSelectChange = useCallback(
		(status) => setPlayerStatus(status),
		[],
	);

	const handleClubSelectChange = useCallback(
		(value) => setPlayerClub(value),
		[],
	);

	const handleBloodGroupChange = (bloodGroup: string) => {
		setPlayer({ ...player, bloodGroup });
	};

	const onSelectPhoto = (e) => {
		const file = e.target.files[0];

		if (!file) {
			setToastContent('Please select a file to continue');
			setToastActive(true);
			return;
		}

		if (!SUPPORTED_IMAGE_MIMETYPES.includes(file.type)) {
			setToastContent('Please select an image file');
			setToastActive(true);
			return;
		}

		if (file.size > MAX_IMAGE_SIZE) {
			setToastContent('Only images with less than 500KB are allowed!');
			setToastActive(true);
			return;
		}

		uploadPhoto(file);
	};

	async function uploadPhoto(photoFile: File) {
		setUploading(true);
		try {
			const photoFormData = new FormData();
			photoFormData.append('photo', photoFile);

			const res: UploadFileResponse = await http.post(
				`/player/upload-photo/${s3Id}`,
				photoFormData,
			);

			setSuccessToastContent('Player photo uploaded successfully!');
			setSuccessToastActive(true);
			setPlayer({ ...player, photo: res.data.key });
		} catch (error) {
			setIsError(true);
			setToastContent('Something went wrong! Please try again.');
			setToastActive(true);
			console.error('Failed to store the player photo in the server', error);
		} finally {
			setUploading(false);
		}
	}

	function onSelectDocument(e) {
		const file = e.target.files[0];

		if (!file) {
			setToastContent('Please select a file to continue');
			setToastActive(true);
			return;
		}

		if (!SUPPORTED_DOCUMENT_MIMETYPES.includes(file.type)) {
			setToastContent('Please select an image or PDF file');
			setToastActive(true);
			return;
		}

		if (file.size > MAX_DOCUMENT_SIZE) {
			setToastContent('Only files with less than 5MB are allowed!');
			setToastActive(true);
			return;
		}

		switch (e.target.id) {
			case 'registrationForm':
				uploadRegistrationForm(file);
				break;
			case 'affidavit':
				uploadAffidavit(file);
				break;
			case 'passport':
				uploadPassport(file);
				break;
			case 'iqama':
				uploadIqama(file);
				break;
			default:
				break;
		}
	}

	async function uploadRegistrationForm(docFile: File) {
		setUploading(true);
		try {
			const regformFormData = new FormData();
			regformFormData.append('file', docFile);
			const res: UploadFileResponse = await http.post(
				`/player/upload-registration-form/${s3Id}`,
				regformFormData,
			);

			setSuccessToastContent('Player registration form uploaded successfully!');
			setSuccessToastActive(true);

			setPlayer({ ...player, registrationForm: res.data.key });
		} catch (error) {
			setIsError(true);
			setToastContent('Something went wrong! Please try again.');
			setToastActive(true);
			console.error(
				'Failed to store the registration form document in the server',
				error,
			);
		} finally {
			setUploading(false);
		}
	}

	async function uploadAffidavit(docFile: File) {
		setUploading(true);
		try {
			const affidavitFormData = new FormData();
			affidavitFormData.append('file', docFile);

			const res: UploadFileResponse = await http.post(
				`/player/upload-affidavit/${s3Id}`,
				affidavitFormData,
			);

			setSuccessToastContent('Player affidavit uploaded successfully!');
			setSuccessToastActive(true);

			setPlayer({ ...player, affidavit: res.data.key });
		} catch (error) {
			setIsError(true);
			setToastContent('Something went wrong! Please try again.');
			setToastActive(true);
			console.error(
				'Failed to store the affidavit document in the server',
				error,
			);
		} finally {
			setUploading(false);
		}
	}

	async function uploadPassport(docFile: File) {
		setUploading(true);
		try {
			const passportFormData = new FormData();
			passportFormData.append('file', docFile);
			const res: UploadFileResponse = await http.post(
				`/player/upload-passport/${s3Id}`,
				passportFormData,
			);

			setSuccessToastContent('Player passport uploaded successfully!');
			setSuccessToastActive(true);

			setPlayer({ ...player, passport: res.data.key });
		} catch (error) {
			setIsError(true);
			setToastContent('Something went wrong! Please try again.');
			setToastActive(true);
			console.error(
				'Failed to store the passport document in the server',
				error,
			);
		} finally {
			setUploading(false);
		}
	}

	async function uploadIqama(docFile: File) {
		setUploading(true);
		try {
			const iqamaFormData = new FormData();
			iqamaFormData.append('file', docFile);
			const res: UploadFileResponse = await http.post(
				`/player/upload-iqama/${s3Id}`,
				iqamaFormData,
			);

			setSuccessToastContent('Player iqama uploaded successfully!');
			setSuccessToastActive(true);

			setPlayer({ ...player, iqama: res.data.key });
		} catch (error) {
			setIsError(true);
			setToastContent('Something went wrong! Please try again.');
			setToastActive(true);
			console.error('Failed to store the iqama document in the server', error);
		} finally {
			setUploading(false);
		}
	}

	async function createPlayer() {
		if (!isValid()) {
			return;
		}
		setLoading(true);

		let status = 0;
		switch (playerStatus) {
			case 'unattended':
				status = 0;
				break;
			case 'onHold':
				status = 1;
				break;
			case 'approved':
				status = 2;
				break;
			case 'fullyApproved':
				status = 3;
				break;
			default:
				break;
		}

		const payload = { ...player, status, clubId: playerClub, s3Id };
		if (playerClub === 'None') {
			payload.clubId = null;
		}

		try {
			await http.post('/create-player', { ...payload });
			window.location.replace('/players');
			return <Redirect to={'/players'} />;
		} catch (error) {
			console.error('Failed to save the club details to the server', error);
			if (error.response && error.response.status === 409) {
				const msg = error.response.data.message || 'Conflict error!';
				setToastContent(msg);
				setToastActive(true);
			} else {
				setIsError(true);
				setToastContent('Something went wrong! Please try again.');
				setToastActive(true);
			}
		} finally {
			setLoading(false);
		}
	}

	function isValid() {
		if (
			player.fullName.trim() === '' ||
			player.email.trim() === '' ||
			player.phone.trim() === ''
		) {
			return false;
		}

		if (!isEmail(player.email)) {
			return false;
		}

		if (player.phone.length < 5) {
			return false;
		}

		return true;
	}

	const toastMarkup = toastActive ? (
		<Toast content={toastContent} error onDismiss={toggleToastActive} />
	) : null;

	const successToastMarkup = successToastActive ? (
		<Toast content={successToastContent} onDismiss={toggleSuccessToastActive} />
	) : null;

	async function loadData() {
		setLoading(true);
		try {
			const clubsList: GetClubsNameListResponse = await http.get(
				'/clubs-names',
			);

			setS3Id(v4());
			setClubs(clubsList.data.clubs);
		} catch (error) {
			setIsError(true);
			setToastActive(true);
			console.error('Failed to load player details from the server');
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		loadData();
		// eslint-disable-next-line
	}, []);

	return (
		<DefaultLayout>
			<Page
				primaryAction={{
					content: 'Save Player',
					disabled: isError || loading || !isValid(),
					onAction: createPlayer,
				}}
				title="Add New Player"
				breadcrumbs={[{ content: 'Player', url: `/players/` }]}
			>
				{toastMarkup}
				{successToastMarkup}
				{isError ? (
					<ErrorBanner />
				) : loading ? (
					<Loading />
				) : (
					<>
						<Card title="Personal Info" sectioned>
							<FormLayout>
								<Thumbnail
									source={
										player.photo
											? SERVER_BASE_URL +
											  '/get-player-image/?key=' +
											  player.photo
											: avatar
									}
									alt="Add Photo"
									size="large"
								/>
								<button
									onClick={() => {
										photoRef.current.click();
									}}
									className="Polaris-Button"
								>
									<input
										type="file"
										id="photo"
										accept="image/*"
										ref={photoRef}
										onChange={onSelectPhoto}
										style={{ display: 'none' }}
									/>
									{uploading ? <Spinner size="small" /> : 'Select Photo'}
								</button>
								<TextField
									value={player.fullName}
									onChange={(fullName) => setPlayer({ ...player, fullName })}
									label="Fullname"
									type="text"
									autoComplete="text"
									placeholder="Enter player fullname. Example: John Doe"
									requiredIndicator
								/>
								<FormLayout.Group>
									<TextField
										value={player.email}
										onChange={(email) => setPlayer({ ...player, email })}
										label="Email"
										type="email"
										autoComplete="email"
										placeholder="Enter the player's email address"
										requiredIndicator
									/>

									<TextField
										value={player.phone}
										onChange={(phone) => setPlayer({ ...player, phone })}
										label="Phone"
										type="number"
										autoComplete="tel"
										placeholder="Enter the player's phone number. Example: 9923487462"
										requiredIndicator
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<Select
										label="Blood Group"
										options={bloodGroups}
										onChange={handleBloodGroupChange}
										value={player.bloodGroup}
										disabled={uploading}
									/>
									<TextField
										value={player.dateOfBirth}
										onChange={(dateOfBirth) =>
											setPlayer({ ...player, dateOfBirth })
										}
										label="Date of Birth"
										type="text"
										autoComplete="tel"
										placeholder="Example: 09/12/1994 (DD/MM/YYYY)"
										disabled={uploading}
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<TextField
										value={player.homeAddress}
										onChange={(homeAddress) =>
											setPlayer({ ...player, homeAddress })
										}
										label="Home Address (India)"
										type="text"
										placeholder="Enter your home address (India)"
										disabled={uploading}
										multiline={4}
									/>
									<TextField
										value={player.saudiAddress}
										onChange={(saudiAddress) =>
											setPlayer({ ...player, saudiAddress })
										}
										label="Saudi Address"
										type="text"
										placeholder="Enter your residential address in Saudi Arabia"
										disabled={uploading}
										multiline={4}
									/>
								</FormLayout.Group>
								<TextField
									value={player.companyName}
									onChange={(companyName) =>
										setPlayer({ ...player, companyName })
									}
									label="Company Name"
									type="text"
									autoComplete="text"
									placeholder="Enter the name of your employer"
								/>
							</FormLayout>
						</Card>
						<Card title="DIFA Settings" sectioned>
							<FormLayout>
								<Banner status="warning">
									<b>Critical Settings:</b> Please make sure the intended values
									are selected
								</Banner>
								<Select
									label="Player Verification Status"
									options={statusOptions}
									onChange={handleStatusSelectChange}
									value={playerStatus}
								/>
								<Select
									label="Club"
									options={clubs}
									onChange={handleClubSelectChange}
									value={playerClub}
									disabled={uploading}
								/>
							</FormLayout>
						</Card>
						<Card title="Document Details" sectioned>
							<FormLayout>
								<FormLayout.Group>
									<TextField
										value={player.iqamaNumber}
										onChange={(iqamaNumber) =>
											setPlayer({ ...player, iqamaNumber })
										}
										label="Iqama Number"
										type="text"
										autoComplete="number"
										placeholder="Enter player's iqama document number"
									/>
									<TextField
										value={player.passportNumber}
										onChange={(passportNumber) =>
											setPlayer({ ...player, passportNumber })
										}
										label="Passport Number"
										type="text"
										autoComplete="number"
										placeholder="Enter player's passport number"
									/>
								</FormLayout.Group>
							</FormLayout>
						</Card>
						<Card title="Documents" sectioned>
							<div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										padding: '10px',
										backgroundColor: '#E5E5E5',
										borderRadius: '5px 5px 0px 0px',
									}}
								>
									<div style={{ flex: '0.50' }}>
										<TextStyle variation="strong">
											DIFA Registration Form
										</TextStyle>
									</div>
									<div style={{ marginLeft: 'auto' }}>
										{player.registrationForm ? (
											<Button
												primary
												onClick={() => {
													window.open(
														SERVER_BASE_URL +
															`/admin/get-player-document?token=${token}&key=${player.registrationForm}`,
														'_blank',
													);
												}}
											>
												View Document
											</Button>
										) : null}
										<button
											style={{ marginLeft: '10px' }}
											onClick={() => {
												registrationFormRef.current.click();
											}}
											className="Polaris-Button"
											disabled={uploading}
										>
											<input
												type="file"
												id="registrationForm"
												accept="image/*,application/pdf"
												ref={registrationFormRef}
												onChange={onSelectDocument}
												style={{ display: 'none' }}
											/>
											{player.registrationForm === ''
												? 'Upload File'
												: 'Change File'}
										</button>
									</div>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										padding: '10px',
										backgroundColor: '#FAFAFA',
									}}
								>
									<div style={{ flex: '0.50' }}>
										<TextStyle variation="strong">Affidavit</TextStyle>
									</div>
									<div style={{ marginLeft: 'auto' }}>
										{player.affidavit ? (
											<Button
												primary
												onClick={() => {
													window.open(
														SERVER_BASE_URL +
															`/admin/get-player-document?token=${token}&key=${player.affidavit}`,
														'_blank',
													);
												}}
											>
												View Document
											</Button>
										) : null}

										<button
											style={{ marginLeft: '10px' }}
											onClick={() => {
												affidavitRef.current.click();
											}}
											className="Polaris-Button"
											disabled={uploading}
										>
											<input
												type="file"
												id="affidavit"
												accept="image/*,application/pdf"
												ref={affidavitRef}
												onChange={onSelectDocument}
												style={{ display: 'none' }}
											/>
											{player.affidavit === '' ? 'Upload File' : 'Change File'}
										</button>
									</div>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										padding: '10px',
										backgroundColor: '#E5E5E5',
									}}
								>
									<TextStyle variation="strong">Passport</TextStyle>
									<div style={{ marginLeft: 'auto' }}>
										{player.passport ? (
											<Button
												primary
												onClick={() => {
													window.open(
														SERVER_BASE_URL +
															`/admin/get-player-document?token=${token}&key=${player.passport}`,
														'_blank',
													);
												}}
											>
												View Document
											</Button>
										) : null}
										<button
											style={{ marginLeft: '10px' }}
											onClick={() => {
												passportRef.current.click();
											}}
											className="Polaris-Button"
											disabled={uploading}
										>
											<input
												type="file"
												id="passport"
												accept="image/*,application/pdf"
												ref={passportRef}
												onChange={onSelectDocument}
												style={{ display: 'none' }}
											/>
											{player.passport === '' ? 'Upload File' : 'Change File'}
										</button>
									</div>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										padding: '10px',
										backgroundColor: '#FAFAFA',
										borderRadius: '0px 0px 5px 5px',
									}}
								>
									<TextStyle variation="strong">Iqama / Saudi Visa Copy</TextStyle>
									<div style={{ marginLeft: 'auto' }}>
										{player.iqama ? (
											<Button
												primary
												onClick={() => {
													window.open(
														SERVER_BASE_URL +
															`/admin/get-player-document?token=${token}&key=${player.iqama}`,
														'_blank',
													);
												}}
											>
												View Document
											</Button>
										) : null}

										<button
											style={{ marginLeft: '10px' }}
											onClick={() => {
												iqamaRef.current.click();
											}}
											className="Polaris-Button"
											disabled={uploading}
										>
											<input
												type="file"
												id="iqama"
												accept="image/*,application/pdf"
												ref={iqamaRef}
												onChange={onSelectDocument}
												style={{ display: 'none' }}
											/>
											{player.iqama === '' ? 'Upload File' : 'Change File'}
										</button>
									</div>
								</div>
							</div>
						</Card>
					</>
				)}
			</Page>
		</DefaultLayout>
	);
}
